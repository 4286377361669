import { useEffect } from "react";
import Booking from "../booking";
import Info from "../info";
import "./index.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { useOrderStore } from "@/stores/order";
import { useLoadingStore } from "@/stores/global";

export default function () {
  const { setTypeDone, setTypeNotDone } = useOrderStore();
  const { setLoading } = useLoadingStore();
  const fetchData = async () => {
    setLoading(true);
    const email = localStorage.getItem("email");

    let done = await axios.get(
      URL_BE + "/invoice/get-by-email/" + email + "?type=done"
    );
    let not_done = await axios.get(
      URL_BE + "/invoice/get-by-email/" + email + "?type=not_done"
    );
    setTypeNotDone(not_done.data);
    setTypeDone(done.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="profile-page__content" style={{ height: "100vh" }}>
      <Tabs className={"profile-page__content-tab"}>
        <TabList
          className={"profile-page__content_tablist"}
          style={{ minWidth: 300 }}
        >
          <Tab className={"tab-item click"}>Đơn hàng đã thanh toán</Tab>
          <Tab className={"tab-item click"}>Đơn hàng chưa thanh toán</Tab>
          <Tab className={"tab-item click"}>Thông tin cá nhân</Tab>
        </TabList>

        <TabPanel className={"tab-panel-item"}>
          <Booking type="done" />
        </TabPanel>
        <TabPanel className={"tab-panel-item"}>
          <Booking type="not-done" />
        </TabPanel>
        <TabPanel className={"tab-panel-item"}>
          <Info />
        </TabPanel>
      </Tabs>
    </div>
  );
}
