import { ContentLayout } from "@/components/Layout";
import Heading from "./heading";
import "./index.css";
import SlideShow from "./slideShow";
import Description from "./description";
import FullCombo from "./fullCombo";
import Policies from "./policies";
import Booking from "./booking";
import data from "../data.json";
import { useTourStore } from "@/stores/tour";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { useLoadingStore } from "@/stores/global";
export default function () {
  const { title } = useParams();
  const { setLoading } = useLoadingStore();
  const { price, currency } = data;
  const { setTour } = useTourStore();

  const fetchData = async () => {
    setLoading(true);
    const result = await axios.get(`${URL_BE}/tours/${title}`);
    setTour(result.data.record);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContentLayout cs={"tour-page__wrapper"}>
      <div id="tour-page__container">
        <Heading />
        <div className="tour-page__container-body">
          <div className="tour-page__container-content">
            <SlideShow />
            <Description />
            <FullCombo />
            <Policies />
          </div>
          <div className="tour-page__container-sidebar">
            <Booking price={price} currency={currency} />
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}
