import { useEffect, useState } from "react";
import "./index.css";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { fieldInvoice } from "@/types/props";
import { useNavigate } from "react-router-dom";
import { useOrderStore } from "@/stores/order";
import ReactPaginate from "react-paginate";
import Skeleton from "react-loading-skeleton";
import { useLoadingStore } from "@/stores/global";
import { TypeIParams } from "@/types/enums";

type props = {
  type: string;
};

export type dataReturn = {
  fields: fieldInvoice;
  id: string;
};

export default function ({ type }: props) {
  const { typeDone, typeNotDone } = useOrderStore();
  const { loading } = useLoadingStore();

  const [data, setData] = useState<dataReturn[]>([]);
  const [display, setDisplay] = useState<dataReturn[]>([]);
  const navigate = useNavigate();
  const perPageRange = 10;
  const [curIndex, setCurIndex] = useState<number>(0);

  useEffect(() => {
    let arr: dataReturn[] = [];
    if (type === "done") {
      setData(typeDone);
      arr = typeDone;
    } else if (type === "not-done") {
      setData(typeNotDone);
      arr = typeNotDone;
    }

    const items = arr.filter((item, index) => index < perPageRange);
    setCurIndex(0);
    setDisplay([...items]);
  }, [typeDone, typeNotDone]);

  function paginate(curPage: number) {
    let skip = perPageRange * curPage - perPageRange;
    let limit = perPageRange;
    const items = data.filter(
      (item, index) => index >= skip && index < skip + limit
    );
    setCurIndex(skip);
    setDisplay([...items]);
  }

  const title = (type: string) => {
    if (type) {
      switch (type) {
        case TypeIParams.ATTRACTION:
          return "Vé";
        case TypeIParams.FAST_BUY:
          return "Vé nhanh";
        case TypeIParams.HOTEL:
          return "Phòng";
        case TypeIParams.OFFER:
          return "Combo Phú Quốc";
        case TypeIParams.TOUR:
          return "Tour";
      }
    } else {
      return "";
    }
  };

  return (
    <div className="profile-page__content__booking">
      <table id="customers">
        <tr>
          <th style={{ width: "300px" }}>#ID</th>
          <th style={{ width: "300px" }}>Order ID</th>
          <th style={{ width: "300px" }}>Type</th>
          <th style={{ width: "300px" }}>Action</th>
        </tr>
        {!loading ? (
          display.map((item, index) => {
            return (
              <tr key={index}>
                <td>{curIndex + index + 1}</td>
                <td>{item.id}</td>
                <td>{title(item.fields.type)}</td>
                <td
                  style={{
                    color: "rgb(46, 144, 250)",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("order/" + item.id);
                  }}
                >
                  Xem chi tiết
                </td>
              </tr>
            );
          })
        ) : (
          <>
            <td
              style={{
                backgroundColor: "white",
              }}
            >
              <Skeleton style={{ width: 200 }} count={10} />
            </td>
            <td
              style={{
                backgroundColor: "white",
              }}
            >
              <Skeleton style={{ width: 200 }} count={10} />
            </td>
            <td
              style={{
                backgroundColor: "white",
              }}
            >
              <Skeleton style={{ width: 200 }} count={10} />
            </td>
            <td
              style={{
                backgroundColor: "white",
              }}
            >
              <Skeleton style={{ width: 200 }} count={10} />
            </td>
          </>
        )}
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Trang tiếp theo >"
        onPageChange={(event) => {
          paginate(event.selected + 1);
        }}
        pageRangeDisplayed={perPageRange}
        pageCount={data.length / 10}
        previousLabel="< Trang trước"
        renderOnZeroPageCount={null}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}
