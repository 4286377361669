import { useTourStore } from "@/stores/tour";
import "./index.css";
import parse from "html-react-parser";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";

export default function () {
  const { tour } = useTourStore();
  const { loading } = useLoadingStore();
  return (
    <div className="tour_detail__body__full-combo tour_container">
      {!loading ? (
        <>
          <div className="full-combo__box-heading">
            <h2>tour trọn gói</h2>
          </div>
          <div className="full-combo__box-body">
            <div className="full-combo__box-body__left-item">
              <div className="title">
                <span>bao gồm</span>
              </div>
              {parse(
                tour?.contains
                  .replace("list-with-icon", "full-combo__box-left")
                  .replace("class", "className")
                  .replaceAll(
                    '<i class="bx bxs-check-circle bx-fw text-primary"></i>',
                    "- "
                  ) ?? ""
              )}
            </div>
            <div className="full-combo__box-body__right-item">
              <div className="title">
                <h2 style={{ fontSize: 20, fontWeight: 600 }}>chưa bao gồm</h2>
              </div>
              {parse(
                tour?.contains
                  .replace("list-with-icon", "full-combo__box-right")
                  .replace("class", "className")
                  .replaceAll(
                    '<i class="bx bxs-check-circle bx-fw text-primary"></i>',
                    "- "
                  ) ?? ""
              )}
            </div>
          </div>
        </>
      ) : (
        <Skeleton width={"100%"} height={"150px"} />
      )}
    </div>
  );
}
