import { useEffect, useState } from "react";
import "./index.css";
import { getUserProfile, saveUserProfile } from "@/components/Function";
import axios from "axios";
import { BASE_GOPHUQUOC_URL, URL_BE } from "@/constants/route";
import { toast } from "react-toastify";
import {
  PAYMENT_RESULT_SUCCESS,
  UPDATE_PROFILE_RESULT_SUCCESS,
} from "@/constants/text";

export default function () {
  const [email, setEmail] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const { email, first_name, last_name, phone_number, access_token } =
      getUserProfile();

    setEmail(email ?? "");
    setLastName(last_name ?? "");
    setFirstName(first_name ?? "");
    setPhone(phone_number ?? "");
    setToken(access_token ?? "");
  }, []);

  const onClick = async () => {
    setLoading(true);
    const result = await axios.put(
      URL_BE + "/auth/update-user",
      {
        email,
        last_name: lastName,
        first_name: firstName,
        phone,
        password,
      },
      {
        headers: { Authorization: token },
      }
    );

    const {
      email: mail,
      first_name,
      last_name,
      phone_number,
    } = result.data.newUser.fields;

    saveUserProfile({
      email: mail,
      first_name: first_name,
      last_name: last_name,
      phone_number,
      access_token: "",
      psw: "",
      id: "",
    });

    toast.success(UPDATE_PROFILE_RESULT_SUCCESS);
    setLoading(false);
  };

  return (
    <div
      className="profile-page__content__info"
      style={{
        backgroundColor: "white",
        padding: "16px 16px",
        borderRadius: 8,
      }}
    >
      <div className="content-wrapper">
        <h3 className="heading-title">Thông tin cá nhân</h3>
        <div id="profilefrm">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12 col-sm-6 o2">
                  <div className="form-group">
                    <label className="profile-page-info__label">
                      Tên khách hàng{" "}
                    </label>
                    <div className="clear"></div>
                    <input
                      type="text"
                      className="form-control form-bg-light"
                      placeholder={firstName ? firstName : "Tên khách hàng"}
                      name="firstname"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      readOnly={false}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 o1">
                  <div className="form-group">
                    <label className="profile-page-info__label">Họ </label>
                    <div className="clear"></div>
                    <input
                      type="text"
                      className="form-control form-bg-light"
                      placeholder={lastName ? lastName : "Họ"}
                      name="lastname"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      readOnly={false}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <label className="profile-page-info__label">E-mail </label>
                    <div className="clear"></div>
                    <input
                      type="email"
                      className="form-control form-bg-light"
                      value={email ? email : "E-mail"}
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <label className="profile-page-info__label">
                      Mật khẩu{" "}
                    </label>
                    <div className="clear"></div>
                    <input
                      className="form-control form-bg-light"
                      type="password"
                      placeholder="Mật khẩu"
                      name="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-6 rtl-ml-auto">
                  <div className="form-group">
                    <label className="profile-page-info__label">
                      Điện thoại{" "}
                    </label>
                    <div className="clear"></div>
                    <input
                      type="text"
                      className="form-control form-bg-light"
                      placeholder={phone ? phone : "Điện thoại"}
                      name="phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className=" btn-container">
                <button
                  className={`py-2 px-4 rounded-lg`}
                  style={{
                    color: "#ffffff",
                    backgroundColor: !loading ? "#2e90fa" : "rgba(0,0,0,0.5)",
                    marginTop: "10px",
                  }}
                  onClick={onClick}
                  disabled={loading}
                >
                  Cập nhập{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
