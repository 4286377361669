import { usePaymentStore } from "@/stores/payment";
import "./index.css";
import { useEffect, useState } from "react";
import { IHotel } from "@/interface";
import {
  getAttraction,
  getHotel,
  getOffer,
  getProduct,
  getTour,
} from "@/components/localStorage";
import { TypeIParams } from "@/types/enums";
import { fieldAttraction } from "@/types/props";
export default function () {
  const { params } = usePaymentStore();
  const [type, setType] = useState<string>("");
  const [item, setItem] = useState<any>(null);
  const [totalAdult, setTotalAdult] = useState<number>(0);
  const [totalKid, setTotalKid] = useState<number>(0);
  const [totalOlder, setTotalOlder] = useState<number>(0);
  const [adults, setAdults] = useState<number>(0);
  const [kid, setKid] = useState<number>(0);
  const [older, setOlder] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [info, setInfo] = useState<fieldAttraction | null>(null);
  const [description, setDescription] = useState<string>("");
  const [openingTimeAndTourTitle, setOpeningTimeAndTourTitle] =
    useState<string>("");
  const [openingTimeAndTour, setOpeningTimeAndTour] = useState<string>("");
  const [usingDateAndStartDate, setUsingDateAndStartDate] =
    useState<string>("");
  const [url, setUrl] = useState<string>("");

  // hotel
  const [roomPrice, setRoomPrice] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [dienTichRoom, setDienTichRoom] = useState<string>("");
  const [hotelTime, setHotelTime] = useState<string>("");
  const [hotelTimeCheckin, setHotelTimeCheckin] = useState<string>("");
  const fetchData = async () => {
    if (params) {
      if (params.type === TypeIParams.ATTRACTION) {
        const data = getAttraction();
        const result = JSON.parse(data ?? "");
        setUrl(result.fields.url.split(",")[0]);
        const price = parseInt(result.fields.price.replaceAll(",", ""));
        setInfo(result.fields);
        setType("Mua vé");
        setDescription(result.fields.location);
        setTotalAdult(price * parseInt(params.adults));
        setTotalKid(price * parseInt(params.kid));
        setTotalOlder(price * parseInt(params.older));
        setTotal(
          price *
            (parseInt(params.adults) +
              parseInt(params.kid) +
              parseInt(params.older))
        );
        setOpeningTimeAndTour(result.fields.opening_time);
        setOpeningTimeAndTourTitle("GIỜ MỞ CỬA");
      } else if (params.type === TypeIParams.FAST_BUY) {
        const ticket = JSON.parse(getAttraction() ?? "");
        setUrl(
          "https://gophuquoc.net/uploads/images/attractions/slider/thumbs/197685_nha-gau-teddy-bear-10.jpg"
        );
        setItem(ticket);
        setDescription(ticket.ShortName);
        setOpeningTimeAndTour("");
        setUsingDateAndStartDate("Thời hạn sử dụng");
        setTitle(ticket.Name);
        if (ticket?.Name.indexOf("Người lớn") >= 0) {
          setTotalAdult(ticket?.Price);
          setAdults(1);
        } else if (ticket?.Name.indexOf("Trẻ em") >= 0) {
          setTotalKid(ticket?.Price);
          setKid(1);
        } else if (ticket?.Name.indexOf("Người cao tuổi") >= 0) {
          setTotalOlder(ticket?.Price);
          setOlder(1);
        }
        setTotal(ticket?.Price ?? 0);
      } else if (params.type === TypeIParams.TOUR) {
        const tour = JSON.parse(getTour());
        setType("Mua tour");
        setTitle(tour.fields.title);
        setOpeningTimeAndTourTitle("HÀNH TRÌNH TOUR");
        setUsingDateAndStartDate("NGÀY KHỞI HÀNH");
        setDescription(tour.fields.location);
        setTotal(tour.fields.price);
        setUrl(tour.fields.urls.split(",")[0]);
      } else if (params.type === TypeIParams.HOTEL) {
        const hotel: IHotel = JSON.parse(getHotel());
        const room_json = JSON.parse(hotel.fields.room_json ?? "");
        setUrl(hotel.fields.url);
        setTitle(hotel.fields.title);
        setType(room_json.fields.title);
        setRoomPrice(room_json.fields.price);
        setDienTichRoom(room_json.fields.dien_tich);
        setDescription(hotel.fields.location ?? "");
        setHotelTime(hotel.fields.time);
        setTotal(room_json.fields.price);
        setHotelTimeCheckin(params.time);
        setUsingDateAndStartDate("Checkin time");
      } else if (params.type === TypeIParams.OFFER) {
        const offer = JSON.parse(getOffer());
        setUrl(offer.fields.url);
        setTitle(offer.fields.title);
        setTotal(offer.fields.price);
        setUsingDateAndStartDate("Ngày check-in");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <div id="payment__body__booking">
      <div className="heading">
        <div className="left-box">
          <img src={url} alt="" />
        </div>
        <div className="right-box">
          <span className="title">{title}</span>
          <span className="tour_name">{type}</span>
        </div>
      </div>
      <div className="body">
        <div className="body_item">
          {!roomPrice ? (
            <>
              <span className="title">NƠI ĐẾN</span>
              <span className="description">{description}</span>
            </>
          ) : (
            <></>
          )}
          {dienTichRoom ? (
            <>
              <span className="title">Địa điểm</span>
              <span className="description">{description}</span>
              <span className="title">Diện tích</span>
              <span className="description">{dienTichRoom}</span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="body_item">
          <span className="title">{openingTimeAndTourTitle}</span>
          <span className="description">{openingTimeAndTour}</span>
        </div>
        <div className="body_item">
          <span className="title">{usingDateAndStartDate}</span>
          {params?.type === TypeIParams.FAST_BUY ? (
            <span className="description">
              Từ {item?.DateTimeUsed.DateFrom} đến {item?.DateTimeUsed.DateTo}
            </span>
          ) : (
            <span className="description">{params && params.time}</span>
          )}
        </div>
        {totalAdult ? (
          <div className="body_item type_person">
            <div className="left-box">Người Lớn</div>
            <div className="center-box">
              x {params?.adults ? params.adults : adults}
            </div>
            <div className="right-box">{totalAdult.toLocaleString()} vnd</div>
          </div>
        ) : (
          <></>
        )}
        {totalKid ? (
          <div className="body_item type_person">
            <div className="left-box">Trẻ em</div>
            <div className="center-box">x {params && params.kid}</div>
            <div className="right-box">{totalKid.toLocaleString()} vnd</div>
          </div>
        ) : (
          <></>
        )}
        {totalOlder ? (
          <div className="body_item type_person">
            <div className="left-box">Người Già</div>
            <div className="center-box">x {params && params.older}</div>
            <div className="right-box">{totalOlder.toLocaleString()} vnd</div>
          </div>
        ) : (
          <></>
        )}
        <div className="bottom_item total">
          <div className="left-box">
            <span>Tổng cộng</span>
          </div>
          <div className="right-box">{total.toLocaleString()} vnd</div>
        </div>
        <div className="bottom_item amount">
          <div className="left-box">Thành tiền</div>
          <div className="right-box">{total.toLocaleString()} vnd</div>
        </div>
        <div className="bottom_item advance_fees">
          <div className="left-box">Trả trước</div>
          <div className="right-box">{total.toLocaleString()} vnd</div>
        </div>
      </div>
    </div>
  );
}
