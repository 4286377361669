import { UText } from "@/components/UText";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { PAYMENT } from "@/constants/route";
import { useTourStore } from "@/stores/tour";
import moment from "moment";
import { useEffect, useState } from "react";
import { setTour } from "@/components/localStorage";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";

type propTypes = {
  price: string;
  currency: string;
};

export default function (props: propTypes) {
  const { price, currency } = props;
  const navigate = useNavigate();
  const { loading } = useLoadingStore();
  const { tour } = useTourStore();
  const [date, setDate] = useState<string>("");
  const [numberCus, setCus] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const minDate = (): string => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  useEffect(() => {
    setDate(minDate());
  }, []);

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setDate(newDate);
  };

  const onClick = () => {
    setTour(JSON.stringify(tour));
    navigate(
      PAYMENT +
        `params?time=${date}&type=tour&id=${tour?.id}&number=${numberCus}`
    );
  };

  useEffect(() => {
    setTotalPrice(parseInt(tour?.price.replaceAll(",", "") ?? "0"));
  }, []);

  return (
    <div className="tour_detail__body__booking tour_container">
      {!loading ? (
        <>
          <div className="booking_container__heading">
            <h2>Đặt Vé</h2>
          </div>
          <div className="booking_container__body">
            <div className="booking_by_date">
              <label htmlFor="booking_by_date">Đặt vé cho ngày:</label>
              <form action="">
                <input
                  type="date"
                  name="booking_by_date"
                  min={minDate()}
                  defaultValue={minDate()}
                  onChange={onChangeDate}
                />
              </form>
            </div>

            <form action="" id="booking_container__submit_form">
              <div
                className="booking_form_item booking_for_adult"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="number"
                  style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    width: 100,
                    padding: "0px 10px",
                    marginRight: "10px",
                  }}
                  defaultValue={1}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0) {
                      setCus(parseInt(e.target.value));
                      setTotalPrice(
                        parseInt(e.target.value) *
                          parseInt(tour?.price.replaceAll(",", "") ?? "0")
                      );
                    }
                  }}
                />{" "}
                /{"\t"}
                <span className="price" style={{ fontSize: 16 }}>
                  {tour?.price} {currency}
                </span>
              </div>

              <div className="booking_order">
                <div className="total">
                  <h3>Tổng tiền</h3>
                </div>
                <div className="price">
                  {totalPrice.toLocaleString()} {currency}
                </div>
                <div className="fees_advance">
                  <UText>
                    Trả trước {totalPrice.toLocaleString()} {currency}
                  </UText>
                </div>
              </div>
              <div className="order_submit click">
                <input
                  type="submit"
                  value="Đặt ngay"
                  className="click"
                  onClick={onClick}
                />
              </div>
            </form>
          </div>
        </>
      ) : (
        <Skeleton width={"100%"} height={"500px"} />
      )}
    </div>
  );
}
