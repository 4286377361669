import { Tour } from "@/interface";
import create from "zustand";

export type tour = {
  tours: Tour[] | [];
  setField: (value: Tour[]) => void;
  tour: Tour | null;
  setTour: (value: Tour) => void;
};

export const useTourStore = create<tour>((set) => ({
  tours: [],
  tour: null,
  setField: (data) => set((state) => ({ tours: data })),
  setTour: (data) => set((state) => ({ tour: data })),
}));
