import { INVOICE, URL_HOMEPAGE, USER } from "@/constants/route";
import { fieldUserProfile } from "../types/props";

export const saveUserProfile = (value: fieldUserProfile) => {
  const { access_token, email, id, first_name, last_name, phone_number } =
    value;
  if (access_token) {
    localStorage.setItem("access_token", access_token ?? "");
  }
  localStorage.setItem("email", email ?? "");
  localStorage.setItem("id", id ?? "");
  localStorage.setItem("first_name", first_name ?? "");
  localStorage.setItem("last_name", last_name ?? "");
  localStorage.setItem("phone_number", phone_number ?? "");
};

export const getUserProfile = () => {
  const access_token = localStorage.getItem("access_token");
  const email = localStorage.getItem("email");
  const id = localStorage.getItem("id");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const phone_number = localStorage.getItem("phone_number");
  return {
    access_token,
    email,
    id,
    first_name,
    last_name,
    phone_number,
  };
};

export const clearUserProfile = () => {
  localStorage.clear();
};

export const isLocatedInSensitiveScreen = () => {
  const href = window.location.href;
  if (href.indexOf(USER) >= 0 || href.indexOf(INVOICE) >= 0) {
    window.location.href = URL_HOMEPAGE;
  }
};
