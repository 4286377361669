import { HTitle } from "@/components/HTitle";
import "./index.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PAYMENT, TICKET, URL_BE } from "@/constants/route";
import axios from "axios";
import { IHotel, IAttractionReturn } from "@/interface";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useAttractionStore } from "@/stores/attraction";
import { ContentLayout } from "@/components/Layout";
import {
  setAttraction,
  setFastBuyTicket,
  setHotel,
  setProduct,
  setTour,
} from "@/components/localStorage";
import { TypeIParams } from "@/types/enums";
import { fieldTicket } from "@/types/props";
export default function () {
  const navigate = useNavigate();
  const { title } = useParams();
  const { setTicket } = useAttractionStore();

  const [tickets, setTickets] = useState<IAttractionReturn[]>([]);
  const [fastBuyTickets, setFastBuyTickets] = useState<fieldTicket[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [location, setLocation] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const { search } = useLocation();
  const [type, setType] = useState<TypeIParams>();

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const type = search.split("=")[1];
      setType(type as TypeIParams);
      let result = null;
      if (type === TypeIParams.ATTRACTION) {
        result = await axios.get(`${URL_BE}/attractions` + "/?page=" + page);
        setLocation(result.data.cur_items[0].fields.location);
        setTotal(result.data.total_items);
        setTickets(result.data.cur_items);
        setTotalPages(result.data.total_pages);
      } else {
        result = await axios.get(
          `${URL_BE}/attractions/ticket-wonder` + "/?page=" + page
        );
        setTotal(result.data.total_items);
        setFastBuyTickets(result.data.cur_items);
        setTotalPages(result.data.total_pages);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [title]);

  const onclick = (title: string) => {
    navigate(TICKET + title);
  };

  const onClickFastBuy = (item: any) => {
    setAttraction(JSON.stringify(item));
    setTicket(item);
    setHotel("");
    setFastBuyTicket(JSON.stringify(item));
    setTour("");
    setProduct("");
    navigate(PAYMENT + "params?type=fast_buy");
  };

  return (
    <ContentLayout cs={"offer-page__wrapper"}>
      <div
        className="content-left col-lg-9 prod-lst"
        style={{ minHeight: "800px" }}
      >
        <section className="box-section-white result-section">
          <div className="title-main-3">
            {!loading ? location : <Skeleton width={200} />}
          </div>
          <div className="number-result">
            {!loading ? (
              <>
                <span>{total}</span> {"kết quả được tìm thấy"}
              </>
            ) : (
              <Skeleton width={200} />
            )}
          </div>
        </section>
        <section className="box-section-white">
          <div className="list-hotel width-[100%]">
            {type === TypeIParams.ATTRACTION
              ? // ATTRACTION
                (!loading ? tickets : [null, null, null, null]).map(
                  (item, index) => {
                    return (
                      <div className="flex mt-4 mb-4" key={index}>
                        <div
                          className="box-img mr-4"
                          onClick={item ? () => onclick(item.id) : () => {}}
                        >
                          {item ? (
                            <img
                              src={item.fields.url.split(",")[0]}
                              alt="Seashells Phu  Quoc Hotel &amp; Spa"
                            />
                          ) : (
                            <Skeleton width={200} height={150} />
                          )}
                        </div>
                        <div className="box-content">
                          <div
                            className="box-left"
                            style={{
                              width: "70%",
                            }}
                          >
                            <div className="top-content">
                              <button
                                onClick={
                                  item ? () => onclick(item.id) : () => {}
                                }
                                className="mb-4"
                              >
                                <HTitle styles={{ fontSize: 20 }}>
                                  {item ? (
                                    item.fields.title.trim()
                                  ) : (
                                    <Skeleton width={100} height={20} />
                                  )}
                                </HTitle>
                              </button>
                              <div
                                className="address-hotel"
                                style={{ fontSize: 16, fontWeight: 400 }}
                              >
                                <span className="input-group-icon icon-location"></span>
                                {item ? (
                                  <>- {item.fields.location}</>
                                ) : (
                                  <Skeleton width={200} height={20} />
                                )}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="box-right">
                            <div className="box-price item-room">
                              {item ? (
                                <div className="text-price">Giá từ</div>
                              ) : (
                                <Skeleton width={200} height={20} />
                              )}
                              <div className="price-room">
                                {item ? (
                                  <div className="price">
                                    {item.fields.price} <small> vnd</small>
                                  </div>
                                ) : (
                                  <Skeleton width={200} height={20} />
                                )}
                              </div>
                              {!loading ? (
                                <p
                                  onClick={
                                    item ? () => onclick(item.id) : () => {}
                                  }
                                  className="click"
                                >
                                  Xem phòng
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              : // FAST_BUY
                (!loading ? fastBuyTickets : [null, null, null, null]).map(
                  (item, index) => {
                    return (
                      <div className="flex mt-4 mb-4" key={index}>
                        <div
                          className="box-img mr-4"
                          onClick={
                            item
                              ? () => onclick(item.Code.toString())
                              : () => {}
                          }
                        >
                          {item ? (
                            <img
                              src={
                                "https://gophuquoc.net/uploads/images/attractions/slider/thumbs/197685_nha-gau-teddy-bear-10.jpg"
                              }
                              alt="Seashells Phu  Quoc Hotel &amp; Spa"
                            />
                          ) : (
                            <Skeleton width={200} height={150} />
                          )}
                        </div>
                        <div className="box-content">
                          <div
                            className="box-left"
                            style={{
                              width: "70%",
                            }}
                          >
                            <div className="top-content">
                              <button
                                onClick={
                                  item ? () => onClickFastBuy(item) : () => {}
                                }
                                className="mb-4"
                              >
                                <HTitle styles={{ fontSize: 20 }}>
                                  {item ? (
                                    item.Name.trim()
                                  ) : (
                                    <Skeleton width={100} height={20} />
                                  )}
                                </HTitle>
                              </button>
                              <div
                                className="address-hotel"
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                }}
                              >
                                <span className="input-group-icon icon-location"></span>
                                {item ? (
                                  <>- {item.Description}</>
                                ) : (
                                  <Skeleton width={200} height={20} />
                                )}{" "}
                                {item ? (
                                  <>
                                    <br />- Ngày bắt đầu:{" "}
                                    {item.DateTimeUsed.DateFrom}
                                    <br />- Ngày kết thúc:{" "}
                                    {item.DateTimeUsed.DateTo}
                                  </>
                                ) : (
                                  <Skeleton width={200} height={20} />
                                )}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="box-right">
                            <div className="box-price item-room">
                              {item ? (
                                <div className="text-price">Giá từ</div>
                              ) : (
                                <Skeleton width={200} height={20} />
                              )}
                              <div className="price-room">
                                {item ? (
                                  <div className="price">
                                    {item.Price.toLocaleString()}{" "}
                                    <small> vnd</small>
                                  </div>
                                ) : (
                                  <Skeleton width={200} height={20} />
                                )}
                              </div>
                              {!loading ? (
                                <p
                                  onClick={
                                    item ? () => onClickFastBuy(item) : () => {}
                                  }
                                  className="click"
                                >
                                  Mua vé
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            <ReactPaginate
              breakLabel="..."
              nextLabel="Trang tiếp theo >"
              onPageChange={(event) => {
                fetchData(event.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="< Trang trước"
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          <div className="text-center"></div>
        </section>
      </div>
    </ContentLayout>
  );
}
