export enum authType {
  NON_AUTH = "NON_AUTH",
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
}

export enum TypeIParams {
  ATTRACTION = "attraction",
  TOUR = "tour",
  FAST_BUY = "fast_buy",
  HOTEL = "hotel",
  OFFER = "offer",
}

export enum TypeStatus {
  DONE = "done",
  NOT_DONE = "not_done",
}
