import './index.css';

export default function () {
  return (
    <div className="col-3">
      <div className="snippet" data-title="dot-spin">
        <div className="stage">
          <div className="dot-spin"></div>
        </div>
      </div>
    </div>
  );
}
