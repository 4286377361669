import { ContentLayout } from "@/components/Layout";
import { Hotels } from "../hotels/hotelList";
import { useNavigate } from "react-router-dom";
import { INVOICE, URL_BE } from "@/constants/route";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

export default function () {
  const navigate = useNavigate();
  const [order_id, setOrderId] = useState<string>("");
  const onClick = async () => {
    const result = await axios.get(URL_BE + "/invoice/" + order_id);
    if (result.data.length > 0) {
      navigate("order/" + order_id);
    } else {
      toast.error("Đơn hàng không tồn tại.");
    }
  };
  return (
    <ContentLayout>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            border: "1px solid rgba(0,0,0,0.5)",
            padding: "10px 20px",
            width: "30%",
            borderRadius: "8px",
            height: "50px",
            display: "flex",
          }}
        >
          <input
            type="text"
            placeholder="Nhập mã đơn hàng để tìm kiếm"
            style={{
              width: "100%",
            }}
            onChange={(e) => {
              setOrderId(e.target.value);
            }}
          />

          <button
            style={{
              width: 150,
              backgroundColor: "rgb(46, 144, 250)",
              color: "white",
              borderRadius: 8,
            }}
            onClick={onClick}
          >
            Tìm kiếm
          </button>
        </div>
      </div>
    </ContentLayout>
  );
}
