import { useLoadingStore } from "@/stores/global";
import { useTourStore } from "@/stores/tour";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";

export default function () {
  const { loading } = useLoadingStore();
  const { tour } = useTourStore();

  const styles = () => (
    <>
      <link
        href="https://gophuquoc.net/themes/default/assets/font-icon/css/boxicons.min.css"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/bootstrap/css/bootstrap.min.css"
        media="screen"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/animate.min.css"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/main.css?v=1693899650"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/plugin.css?v=1693899650"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/style.css?v=1693899650"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/style.css?v=1693899650"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/mobile.css?v=1693899650"
        rel="stylesheet"
      ></link>
      <link
        href="https://gophuquoc.net/themes/default/assets/css/custom.css?v=1693899650"
        rel="stylesheet"
      ></link>
    </>
  );

  return (
    <div
      className="tour_detail__body__description tour_container"
      id="tour_description"
    >
      {styles()}
      {!loading ? (
        parse(tour?.description ?? "")
      ) : (
        <Skeleton width={"100%"} height={500} />
      )}
    </div>
  );
}
