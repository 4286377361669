import { useAuthStore } from "@/stores/auth";
import LoginForm from "./LoginForm";
import { authType } from "@/types/enums";
import RegisterForm from "./RegisterForm";
import "./index.css";

type AuthType = {
  children: React.ReactNode;
};

export default function ({ children }: AuthType) {
  const { type } = useAuthStore();

  const Modal = type === authType.LOGIN ? <LoginForm /> : <RegisterForm />;

  return (
    <div>
      {type !== authType.NON_AUTH ? Modal : <></>}
      {children}
    </div>
  );
}
