type HeadProps = {
  title?: string;
  textColor?: string;
  backgroundColor?: string;
  csContainer?: string;
  csBtn?: string;
  onClick?: any;
};

export const Button = ({
  title = "",
  textColor = "#ffffff",
  backgroundColor = "#2e90fa",
  csContainer = "",
  csBtn = "",
  onClick,
}: HeadProps = {}) => {
  return (
    <div
      className={`flex justify-center ${csContainer} click`}
      onClick={onClick}
    >
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#supplierSignin"
        className={`py-2 px-4 rounded-lg ${csBtn}`}
        style={{ color: textColor, backgroundColor: backgroundColor }}
      >
        {title}
      </button>
    </div>
  );
};
