import { authType } from "@/types/enums";
import { fieldLoginForm, fieldRegisterForm } from "@/types/props";
import create from "zustand";

export type auth = {
  type: authType;
  token: string;
  loginForm: fieldLoginForm | null;
  registerForm: fieldRegisterForm | null;
  setLoginForm: (value: fieldLoginForm) => void;
  setRegisterForm: (value: fieldRegisterForm) => void;
  setType: (value: authType) => void;
  setToken: (value: string) => void;
  clearLoginForm: () => void;
  clearRegisterForm: () => void;
};

export const useAuthStore = create<auth>((set) => ({
  type: authType.NON_AUTH,
  loginForm: null,
  registerForm: null,
  token: "",
  setLoginForm: (data) => set((state) => ({ loginForm: { ...data } })),
  setRegisterForm: (data) => set((state) => ({ registerForm: { ...data } })),
  clearLoginForm: () => set((state) => ({ loginForm: null })),
  clearRegisterForm: () => set((state) => ({ registerForm: null })),
  setType: (value) => set((state) => ({ type: value })),
  setToken: (value) => set((state) => ({ token: value })),
}));
