import "./index.css";
import { Carousel } from "react-responsive-carousel";
import { useEffect } from "react";
import { useTourStore } from "@/stores/tour";
import Skeleton from "react-loading-skeleton";
import { useLoadingStore } from "@/stores/global";
export default function () {
  const { tour } = useTourStore();
  const { loading } = useLoadingStore();

  const setMovieKey = (index: number) => {
    setTimeout(() => {
      const img = document.querySelector<HTMLElement>(
        "#tour_slideshow > div > div.carousel.carousel-slider"
      );
      const imgContainer = document.querySelectorAll<HTMLElement>(
        "#tour_slideshow > div > div.carousel.carousel-slider > div > ul > li.slide > div > img"
      );

      if (imgContainer && img) {
        img.style.height = imgContainer[index]?.offsetHeight.toString() + "px";
        img.style.transition = "height 1s ease-in-out";
      }
    }, 1000);
  };

  useEffect(() => {
    setMovieKey(0);
  }, [tour]);

  const renderCustomThumbs = () => {
    const urls = tour?.urls.split(",") ?? [];
    const thumbs = urls.map((item, index) => {
      return (
        <div
          className="tour_carousel thumb_item"
          key={index}
          onClick={() => setMovieKey(index)}
        >
          <div className="tour_carousel__background"></div>
          <img src={item} alt="" />
        </div>
      );
    });
    return thumbs;
  };
  return (
    <div
      className="tour_detail__body__slide-show tour_container"
      id="tour_slideshow"
    >
      <ul className="tour_content_navigation">
        <li>
          <a href="#description">GIỚI THIỆU</a>
        </li>
        <li>
          <a href="#policies">CHÍNH SÁCH</a>
        </li>
      </ul>

      {!loading ? (
        <Carousel renderThumbs={renderCustomThumbs} onChange={setMovieKey}>
          {tour?.urls.split(",").map((item, index) => {
            return (
              <div className="tour_carousel_item" key={index}>
                <img src={item} alt="" />
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Skeleton width={"100%"} height={500} />
      )}
    </div>
  );
}
