export const setAttraction = (value: string) => {
  localStorage.setItem("attraction", value);
};

export const getAttraction = () => {
  return localStorage.getItem("attraction") ?? "";
};

export const setProduct = (value: string) => {
  localStorage.setItem("product", value);
};

export const getProduct = () => {
  return localStorage.getItem("product") ?? "";
};

export const setTour = (value: string) => {
  localStorage.setItem("tour", value);
};

export const getTour = () => {
  return localStorage.getItem("tour") ?? "";
};

export const setHotel = (value: string) => {
  localStorage.setItem("hotel", value);
};

export const getHotel = () => {
  return localStorage.getItem("hotel") ?? "";
};

export const setRoom = (value: string) => {
  localStorage.setItem("room", value);
};

export const getOffer = () => {
  return localStorage.getItem("offer") ?? "";
};

export const setOffer = (value: string) => {
  localStorage.setItem("offer", value);
};

export const getRoom = () => {
  return localStorage.getItem("room") ?? "";
};

export const setFastBuyTicket = (value: string) => {
  localStorage.setItem("fast_buy", value);
};

export const getFastBuyTicket = () => {
  return localStorage.getItem("fast_buy") ?? "";
};

export const setInvoiceId = (value: string) => {
  localStorage.setItem("invoice_id", value);
};

export const getInvoiceId = () => {
  return localStorage.getItem("invoice_id") ?? "";
};

export const setInvoiceType = (value: string) => {
  localStorage.setItem("invoice_type", value);
};

export const getInvoiceType = () => {
  return localStorage.getItem("invoice_type") ?? "";
};

// sensitive information

export const getToken = () => {
  return localStorage.getItem("access_token");
};

export const setToken = (value: string) => {
  localStorage.setItem("access_token", value);
};

export const clearAll = () => {
  localStorage.clear();
};
