import { ContentLayout } from "@/components/Layout";
import Form from "./Form";
import "./index.css";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useInvoiceStore } from "@/stores/invoice";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { fieldInvoice } from "@/types/props";
import {
  getAttraction,
  getHotel,
  getOffer,
  getTour,
  setAttraction,
  setHotel,
  setInvoiceType,
  setProduct,
  setRoom,
} from "@/components/localStorage";
import Skeleton from "react-loading-skeleton";
import ConfirmationTour from "./ConfirmationTour";
import GuestInfo from "./GuestInfo";
import TourDetail from "./TourDetail";
import Total from "./Total";
import { TypeIParams } from "@/types/enums";

export default function () {
  const href = window.location.href;
  const { setField, invoice } = useInvoiceStore();
  const [product, setProductState] = useState<any>(null);
  const [room, setRoomState] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");

  const [name, setName] = useState<string>("");

  const fetchData = async () => {
    setLoading(true);
    const href = window.location.href;
    const title = href.split("/")[href.split("/").length - 1];

    const result = await axios.get(URL_BE + "/invoice/" + title);

    const invoice: fieldInvoice = result.data[0].fields;
    invoice.record_id = result.data[0].record_id;
    setInvoiceType(invoice.type);
    setField(result.data[0].fields);

    setField(invoice);
    if (invoice.type === TypeIParams.HOTEL) {
      const strHotel = invoice.product_json;

      if (strHotel) {
        const hotel = JSON.parse(strHotel);
        const room = JSON.parse(hotel.fields.room_json);

        setHotel(invoice.product_json);
        setRoom(JSON.stringify(room));
        setRoomState(room);
        setName(room.fields.title);
        setDescription(hotel.fields.title);
        setUrl(hotel.fields.url);
      }
    } else if (invoice.type === TypeIParams.FAST_BUY) {
      const rawData = invoice.product_json;

      if (rawData) {
        const productJson = JSON.parse(rawData);
        setName(productJson.ShortName);
        setProduct(invoice.product_json);
        setUrl(
          "https://gophuquoc.net/uploads/images/attractions/slider/thumbs/197685_nha-gau-teddy-bear-10.jpg"
        );
      }
    } else if (invoice.type === TypeIParams.OFFER) {
      const rawData = invoice.product_json;

      if (rawData) {
        const offer = JSON.parse(rawData);
        setUrl(offer.fields.url);
        setName(offer.fields.title);
      }
    } else if (invoice.type === TypeIParams.ATTRACTION) {
      const productJson = invoice.product_json;
      if (productJson) {
        const product_json = JSON.parse(productJson);
        setAttraction(productJson);
        setUrl(product_json.url.split(",")[0]);
        setName(product_json.title);
      }
    } else if (invoice.type === TypeIParams.TOUR) {
      const rawData = invoice.product_json;
      if (rawData) {
        const tour = JSON.parse(rawData);
        setUrl(tour.fields.urls.split(",")[0]);
        setName(tour.fields.title);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ContentLayout cs={"invoice-page__wrapper"}>
        <div>
          {!loading ? (
            <div className="invoice-page__container">
              <Form />
              <ConfirmationTour />
              {invoice?.type === "tour" && <GuestInfo />}
              <TourDetail />
              <Total />
            </div>
          ) : (
            <Skeleton width={"1000px"} height={500} />
          )}
          {!loading ? (
            <div className="invoice-page__sidebar_container">
              <div className="invoice-page__sidebar">
                <a href="#" className="product-small-item">
                  <img src={url} alt="[Khứ hồi] Cáp Treo Hòn Thơm" />
                  <h6>{name}</h6>
                </a>
                <div className="separate"></div>
                <div className="body">
                  <h4 className="mt-10">Quy định chung</h4>
                  <p>
                    - Không đem vật nuôi lên cabin
                    <br />
                    - Không đem các đồ đạc khác ngoài đồ đạc cá nhân lên cabin
                    <br />- Du khách cần giữ lại vé đối với vé khứ hồi
                  </p>
                </div>
              </div>
              <aside className="sidebar-contact__wrapper">
                <h4 className="text-gray ">Liên hệ chúng tôi</h4>
                <span className="text-muted">
                  Nếu bạn cần thêm thông tin về vé này hoặc gặp vấn đề về thanh
                  toán, vui lòng liên lạc với chúng tôi.
                </span>
                <address className="contact-details">
                  <span className="text-info font-15 text-uppercase">
                    <i className="bx bxs-phone bx-md bx-fw text-danger"></i>{" "}
                    (+84) 852 292 598 - 02973 855 598
                  </span>
                  <br />
                  <a
                    className="text-muted pl-24"
                    href="mailto:info@gophuquoc.net"
                  >
                    info@gophuquoc.net
                  </a>
                </address>
              </aside>
            </div>
          ) : (
            <div className="invoice-page__sidebar_container">
              <Skeleton width={"300px"} height={500} />
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
}
