import { useEffect, useState } from "react";
import "./index.css";
import moment from "moment";

export default function () {
  const [date, setDate] = useState<string>("");

  return (
    <div className="heading_container">
      <div className="right-box">
        <span className="h4"></span>
      </div>
    </div>
  );
}
