import UserPage from "@/features/users/routes/UserPage";
import Order from "@/features/order";

export const protectedRoutes = [
  {
    path: "/user",
    element: <UserPage />,
  },
  {
    path: "/user/order/:id",
    element: <Order />,
  },
];
