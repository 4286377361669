import { useTourStore } from "@/stores/tour";
import "./index.css";
export default function () {
  const { tour } = useTourStore();

  return (
    <div>
      <div className="tour_detail__heading tour_container">
        <div className="tour_detail__heading_item tour_detail__heading_top">
          <div className="left-box">
            <h2>{tour?.title}</h2>
          </div>
          <div className="right-box">
            <span className="title">Giá tốt nhất</span>
            <span className="price">{tour?.price}</span>
          </div>
        </div>
        <div className="tour_detail__heading_item tour_detail__heading_bottom">
          <div className="location left-box">
            <span>{tour?.location}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
