import { fieldInvoice } from "@/types/props";
import create from "zustand";

export type invoice = {
  invoice: fieldInvoice | null;
  setField: (value: fieldInvoice) => void;
};

export const useInvoiceStore = create<invoice>((set) => ({
  invoice: null,
  setField: (data) => set((state) => ({ invoice: data })),
}));
