import { ContentLayout } from "@/components/Layout";
import CompoPhuQuoc from "./comboPhuQuoc";
import { TopBanner } from "@/components/TopBanner";
import PopularPosition from "./popularPosition";
import TicketRoom from "./ticketRoom";
import LocalTours from "./localTour";
import axios from "axios";
import { useEffect, useState } from "react";
import { URL_BE } from "@/constants/route";
import { useAttractionStore } from "@/stores/attraction";
import { useHotelStore } from "@/stores/hotel";
import { useOfferStore } from "@/stores/offer";
import { useCategoryHotelStore } from "@/stores/category_hotel";
import { useTourStore } from "@/stores/tour";
import { useLoadingStore } from "@/stores/global";
import "./index.css";

// const COMPONENTS = [
//   {
//     order: 1,
//     component: <CompoPhuQuoc key={1} />,
//     name_component: "CompoPhuQuoc",
//   },
//   {
//     order: 2,
//     component: <PopularPosition key={2} />,
//     name_component: "PopularPosition",
//   },
//   {
//     order: 3,
//     component: <TicketRoom key={3} />,
//     name_component: "TicketRoom",
//   },
//   {
//     order: 4,
//     component: <LocalTours key={4} />,
//     name_component: "LocalTours",
//   },
// ];

export default function () {
  // const { setField: setAttractions, setTickets } = useAttractionStore();
  // const { setField: setOffers } = useOfferStore();
  // const { setField: setCategoryHotel } = useCategoryHotelStore();
  // const { setField: setHotels } = useHotelStore();
  // const { setField: setTour } = useTourStore();
  // const { setLoading } = useLoadingStore();
  // const [components, setComponents] = useState<any>(COMPONENTS);

  // let endpoints = [
  //   `${URL_BE}/attraction?page=10&perpage=6`,
  //   `${URL_BE}/offer`,
  //   `${URL_BE}/category_hotel`,
  //   `${URL_BE}/hotel?category_id=18`,
  //   `${URL_BE}/tour`,
  // ];

  const fetchData = async () => {
    axios.get(
      "https://open.larksuite.com/open-apis/bitable/v1/apps/EWWKbY6gRaJsJhsV5X3lJAdcgEb/tables/tblkk5AFTvSgCyUG/records/recSIUVdHP",
      {
        headers: {
          Authorization:
            "bearer u-dH11dHUbFaoUtmSp2SYkkq156XYu5gNFOWG0g4g027mV",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    // setLoading(true);
    // const promise1 = axios.get(endpoints[0]);
    // const promise2 = axios.get(endpoints[1]);
    // const promise3 = axios.get(endpoints[2]);
    // const promise4 = axios.get(endpoints[3]);
    // const promise5 = axios.get(endpoints[4]);
    // Promise.all([promise1, promise2, promise3, promise4, promise5])
    //   .then(function (data) {
    //     setAttractions(data[0].data.data);
    //     setOffers(data[1].data.data);
    //     setCategoryHotel(data[2].data.data);
    //     setHotels(data[3].data.data);
    //     setTour(data[4].data.data);
    //   })
    //   .then(function () {
    //     setLoading(false);
    //   })
    //   .catch((e) => {});
    // const result = await axios.get(`${URL_BE}/attraction/ticket-wonder`);
    // setTickets(result.data.data ?? []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ContentLayout>
        <></>
        {/* <TopBanner name="top-banner.jpg" />
        {components &&
          components.map((item: any, index: number) => item.component)} */}
      </ContentLayout>
    </>
  );
}
