import { ContentLayout } from "@/components/Layout";
import { SideBar } from "./SideBar";
import "./index.css";
import { Content } from "./Content";
import { useLoadingStore } from "@/stores/global";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useOfferStore } from "@/stores/offer";
export default function () {
  const { title } = useParams();
  const { setLoading, setOffer } = useOfferStore();
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await axios.get(URL_BE + "/offers/" + title);
      setOffer(result.data.record);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <ContentLayout cs={"offer-page__wrapper"}>
      <div id="offer-page__container" style={{ padding: "50px 0" }}>
        <Content />
        <SideBar />
      </div>
    </ContentLayout>
  );
}
