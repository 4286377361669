export const PAYMENT_RESULT_SUCCESS =
  "Quý khách đã thanh toán đơn hàng thành công. Xin vui lòng kiểm tra mail";
export const PAYMENT_RESULT_FAIL =
  "Có lỗi xảy ra lúc thanh toán, Quý Khách vui lòng liên hệ kỹ thuật viên để yêu cầu hỗ trợ.";
export const UPDATE_PROFILE_RESULT_SUCCESS =
  "Quý khách đã cập nhựt thành công.";
// TICKET
export const TICKET_ROOM = "Phòng vé";
export const BUY_TICKET_ROOM = "Mua vé nhanh";
export const PRICE_TICKET_FROM = "Giá vé từ";
export const TICKET = "vé";
export const TICKET_IMG_DEFAULT =
  "https://gophuquoc.net/uploads/images/attractions/slider/thumbs/197685_nha-gau-teddy-bear-10.jpg";

export const SEE_MORE = "Xem thêm";
export const LOCAL_TOUR = "Tour địa phương";
export const CURRENCY = "vnd";

export const POPULAR_POSITION = "Loại lưu trú phổ biến";
export const POINT_POSITION = "điểm lưu trú";
export const PRICE_FROM = "Giá từ";

export const PASSWORD_LENGTH = "Mật khẩu phải có ít nhất 8 ký tự";
export const PHONE_LENGTH = "Số điện thoại phải có ít nhất 10 ký tự";
export const PASSWORD_NOT_MATCH = "Mật khẩu không trùng khớp.";
export const EMAIL_NOT_MATCH = "Email không đúng định dạng.";
