import { HTitle } from "@/components/HTitle";
import data from "../data.json";
import "./index.css";
import "@/index.css";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";
export const Facilities = () => {
  const { facilities } = data;
  const { loading } = useLoadingStore();
  return (
    <>
      <section
        className="convenient-section box-section-white hotel_section room_detail"
        id="convenient-section"
        style={{ width: "80%" }}
      >
        <HTitle>Tiện nghi chổ nghỉ</HTitle>
        {!loading ? (
          <div className="list-convenient mt-4">
            <div className="item">
              <div className="title">
                <img
                  src="https://gophuquoc.net/themes/gpqTheme/assets/img//amt-0.png"
                  alt="Hình ảnh Dịch vụ khách sạn"
                />{" "}
                Dịch vụ khách sạn{" "}
              </div>
              <ul>
                {facilities.services.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
            <div className="item">
              <div className="title">
                <img
                  src="https://gophuquoc.net/themes/gpqTheme/assets/img//amt-2.png"
                  alt="Hình ảnh Tiện nghi phòng"
                />{" "}
                Tiện nghi phòng{" "}
              </div>
              <ul>
                <ul>
                  {facilities.facilities_room.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </ul>
            </div>
            <div className="item">
              <div className="title">
                <img
                  src="https://gophuquoc.net/themes/gpqTheme/assets/img//amt-5.png"
                  alt="Hình ảnh Các hoạt động"
                />{" "}
                Các hoạt động{" "}
              </div>
              <ul>
                <ul>
                  {facilities.activities.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </ul>
            </div>
          </div>
        ) : (
          <Skeleton width={"100%"} height={300} />
        )}
      </section>
    </>
  );
};
