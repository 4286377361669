import HomePage from "@/features/homePage";
import OfferPage from "@/features/offer";
import HotelPage from "@/features/hotels";
import HotelDetailPage from "@/features/hotels/detail";
import TourDetailPage from "@/features/tours/tourDetail";
import PaymentPage from "@/features/payment";
import InvoicePage from "@/features/invoice";
import SearchPage from "@/features/search";
import AttractionPage from "@/features/tickets";
import Order from "@/features/order";
import Tickets from "@/features/tickets/ticketList";
import {
  HOMEPAGE,
  HOTEL,
  HOTELS,
  INVOICE,
  OFFER,
  PAYMENT,
  SEARCH_ORDER,
  TICKET,
  TICKETS,
  TOURS_DETAIL,
} from "@/constants/route";

export const publicRouter = [
  {
    path: `${HOMEPAGE}`,
    element: <HomePage />,
  },
  {
    path: `${OFFER}:title`,
    element: <OfferPage />,
  },
  {
    path: `${HOTELS}:title`,
    element: <HotelPage />,
  },
  {
    path: `${HOTEL}:title`,
    element: <HotelDetailPage />,
  },
  {
    path: `${TICKET}/page/view-more`,
    element: <Tickets />,
  },
  {
    path: `${TICKETS}:title`,
    element: <AttractionPage />,
  },
  {
    path: `${TOURS_DETAIL}:title`,
    element: <TourDetailPage />,
  },
  {
    path: `${PAYMENT}:title`,
    element: <PaymentPage />,
  },
  ,
  {
    path: `${INVOICE}:title`,
    element: <InvoicePage />,
  },
  ,
  {
    path: `${SEARCH_ORDER}`,
    element: <SearchPage />,
  },
  ,
  {
    path: `${OFFER}:title`,
    element: <Order />,
  },
  {
    path: `${SEARCH_ORDER}order/:title`,
    element: <Order />,
  },
];
