import { primary } from "../Colors";
import { HTitle } from "../HTitle";
import { List } from "../List";

export const Foot = () => {
  return (
    <footer
      className={`flex justify-center border-t-4 border-[#2e90fa] border-solid`}
      style={{ backgroundColor: "white", marginTop: 20 }}
    >
      <div
        className="flex justify-between w-[90%] items-start"
        style={{
          padding: "10px 0 10px",
        }}
      >
        <ul>
          <li>
            <img
              src={require("../../assets/logo.png").default}
              alt=""
              className="w-28"
            />
          </li>
          <List>(+84) 852 292598 855 292598</List>
          <List>info@gophuquoc.net</List>
        </ul>

        <ul>
          <div>
            <HTitle styles={{ fontSize: 20 }}>Hỗ trợ</HTitle>
            <div />
          </div>
          <List>Hướng dẫn đặt phòng</List>
          <List> Liên hệ</List>
        </ul>

        <ul>
          <HTitle styles={{ fontSize: 20 }}>Về chúng tôi</HTitle>
          <List>Điều khoản sử dụng</List>
          <List>Bảo mật thông tin</List>
          <List> Phương thức thanh toán</List>
        </ul>
      </div>
    </footer>
  );
};
