import { Carousel } from "react-responsive-carousel";
import "./index.css";
import parse from "html-react-parser";
import { HTitle } from "@/components/HTitle";
import { UText } from "@/components/UText";
import { useHotelStore } from "@/stores/hotel";
import {
  setAttraction,
  setHotel,
  setProduct,
  setTour,
} from "@/components/localStorage";
import { useNavigate } from "react-router-dom";
import { PAYMENT } from "@/constants/route";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";

const IMG = [
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/431008_365525177.jpeg",
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/742798_365525159.jpeg",
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/936918_365525192.jpeg",
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/172283_365529952.jpeg",
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/368551_365529971.jpeg",
  "https://gophuquoc.net/uploads/images/hotels/roomType/photos/290884_365535031.jpeg",
];

export const RoomSection = () => {
  const { hotel } = useHotelStore();
  const { loading } = useLoadingStore();
  const navigate = useNavigate();
  const [date, setDate] = useState<string>("");

  const minDate = (): string => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  useEffect(() => {
    setDate(minDate());
  }, []);

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setDate(newDate);
  };

  return (
    <>
      <section className="room-section hotel_section box-section-gray room_detail">
        <div
          className="box-container"
          style={{
            paddingTop: 10,
          }}
        >
          <div className="box-content">
            <div className="box-left box-sticky">
              <div className="box-slider-rooom">
                <div className="slick-list draggable">
                  {!loading ? (
                    <Carousel infiniteLoop={true} autoPlay={true}>
                      {IMG.map((item, index) => {
                        return (
                          <div key={index}>
                            <img src={item} alt="Hình ảnh  Superior Room"></img>
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <Skeleton width={"100%"} height={"500px"} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="box-right list-item-room">
            {!loading ? (
              hotel?.rooms.map((room, index) => {
                return (
                  <div className="item-room" key={index}>
                    <div className="top-room">
                      <div className="box-left">
                        {room?.fields.title}{" "}
                        <div className="info-room">
                          <div className="item">
                            <span className="icon">
                              <img
                                src={
                                  "https://gophuquoc.net/themes/gpqTheme/assets/img//room_area.png"
                                }
                                alt=""
                              ></img>
                            </span>
                            {parse(room?.fields.dien_tich ?? "")}
                          </div>
                        </div>
                      </div>
                      <div className="price-room">
                        <div className="price">
                          {room?.fields.price} <small> VND</small>
                        </div>
                        <div
                          className="number-vacancy"
                          style={{ fontSize: 12 }}
                        >
                          Còn ({room?.fields.spare_room}) phòng trống
                        </div>
                      </div>
                    </div>
                    <hr />
                    <input
                      type="date"
                      name="booking_by_date"
                      defaultValue={minDate()}
                      min={minDate()}
                      onChange={onChangeDate}
                      style={{
                        fontSize: "16px",
                      }}
                    />
                    <div className="content-room">
                      <div className="list-endow">
                        <div className="item">
                          <div className="text" style={{ fontSize: 12 }}>
                            - Bao gồm bữa sáng
                          </div>
                        </div>
                        <div className="item">
                          <div className="text" style={{ fontSize: 12 }}>
                            - Miễn phí hủy phòng
                          </div>
                        </div>
                        <div className="item">
                          <div className="text" style={{ fontSize: 12 }}>
                            - Có xe đưa đón
                          </div>
                        </div>
                      </div>
                      <button
                        style={{
                          fontSize: 16,
                          backgroundColor:
                            parseInt(room?.fields.spare_room) <= 0
                              ? "rgba(0,0,0,0.3)"
                              : "rgb(46, 144, 250)",
                          color: "white",
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 8,
                          marginTop: 10,
                        }}
                        disabled={parseInt(room?.fields.spare_room) <= 0}
                        onClick={() => {
                          const room_json = JSON.stringify(room);
                          hotel.fields.room_json = room_json;
                          setHotel(JSON.stringify(hotel));
                          setAttraction("");
                          setTour("");
                          setProduct("");
                          navigate(
                            PAYMENT +
                              `params?time=${date}&type=hotel&id=${hotel?.id}`
                          );
                        }}
                      >
                        Đặt phòng
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <Skeleton height={"100%"} />
            )}
            {hotel && hotel.rooms.length === 0 && (
              <div
                className="item-room"
                style={{
                  height: "100%",
                  fontSize: 24,
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Không còn phòng trống
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
