import { Button } from "@/components/Button";
import { UText } from "@/components/UText";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { useEffect, useState } from "react";
import { useAuthStore } from "@/stores/auth";
import { authType } from "@/types/enums";
import logo from "@/assets/logo.png";
import { USER } from "@/constants/route";
import { clearAll, getToken } from "../localStorage";
import { getUserProfile, isLocatedInSensitiveScreen } from "../Function";
import { toast } from "react-toastify";
export const Head = () => {
  const navigate = useNavigate();
  const { setType, token, setToken } = useAuthStore();
  const [auth, setAuth] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [hide, setHide] = useState<boolean>(false);
  const data = useLocation();
  useEffect(() => {
    const _token = getToken();
    if (_token) {
      const { last_name } = getUserProfile();
      setLastName(last_name ?? "");
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [token]);

  useEffect(() => {
    const { pathname } = data;
    if (pathname.indexOf(USER) >= 0) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);

  const onClick = () => {
    navigate("/");
  };

  const openLoginForm = () => {
    setType(authType.LOGIN);
  };
  const openRegisterForm = () => {
    setType(authType.REGISTER);
  };

  const logout = () => {
    const result = window.confirm("Bạn có muốn đăng xuất?");
    if (result) {
      setToken("");
      setAuth(false);
      isLocatedInSensitiveScreen();
      clearAll();
      toast.success("Đăng xuất thành công");
    }
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      const position = window.scrollY;
      const parent = document.querySelector<HTMLElement>(
        ".heading_page_container"
      );
      const element = document.querySelector<HTMLElement>(
        ".heading_page_wrapper"
      );
      const authElements = document.querySelector<HTMLElement>(
        ".heading_page_wrapper .right-box .top-item"
      );

      if (position > 94 && element && parent && authElements) {
        element.style.width = "80%";
        parent.style.position = "fixed";
        parent.style.top = "0px";
        parent.style.width = "100%";
        parent.style.backgroundColor = "white";
        parent.style.zIndex = "1000";
        parent.style.borderBottomWidth = "1px";
        parent.style.borderStyle = "solid";
        parent.style.borderTop = "none";
        parent.style.borderLeft = "none";
        parent.style.borderRight = "none";
        parent.style.borderBottomColor = "#d2d2d2";
        authElements.style.display = "none";
        parent.style.zIndex = "20";
      } else if (position <= 94 && element && parent && authElements) {
        element.style.width = "90%";
        parent.style.position = "relative";
        parent.style.border = "none";
        authElements.style.display = "flex";
        parent.style.zIndex = "10";
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToProfile = () => {
    navigate(USER);
  };

  return (
    <div className="flex justify-center heading_page_container">
      <div className="flex justify-between items-center w-[90%] py-1 heading_page_wrapper">
        <img
          src={logo}
          alt=""
          className="click left-box"
          onClick={onClick}
          style={{ width: 100 }}
        />
        <div className="right-box">
          {auth ? (
            <>
              <div
                className="user-name"
                style={{
                  textAlign: "right",
                  marginBottom: 10,
                }}
              >
                Xin chào{" "}
                <span
                  style={{
                    color: "rgb(46, 144, 250)",
                    fontSize: "20px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={!hide ? navigateToProfile : () => {}}
                >
                  {lastName}
                </span>{" "}
                <span
                  style={{
                    color: "rgb(46, 144, 250)",
                    borderColor: "rgb(46, 144, 250)",
                    border: "1px solid",
                    padding: "5px 10px",
                    borderRadius: 8,
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                  onClick={logout}
                >
                  Đăng xuất
                </span>
              </div>
            </>
          ) : (
            <ul className="top-item">
              <li onClick={openRegisterForm} className="click">
                Đăng ký
              </li>
              <li onClick={openLoginForm} className="click">
                Đăng nhập
              </li>
            </ul>
          )}
          {!hide ? (
            <ul
              className="flex justify-between items-center space-x-1.5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <li>
                <a
                  className={`py-2 px-4 rounded-lg`}
                  style={{ color: "#ffffff", backgroundColor: "#2e90fa" }}
                  target="_blank"
                  href="/search-order"
                >
                  Tra cứu đơn hàng
                </a>
              </li>
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
