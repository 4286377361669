import { ContentLayout } from "@/components/Layout";

import Heading from "../../components/heading";
import "./index.css";
import Content from "../../components/content";

export default function () {
  console.log(345);

  return (
    <div style={{ backgroundColor: "#E6EAED" }}>
      <ContentLayout cs="profile-page__wrapper">
        <Heading />
        <Content />
      </ContentLayout>
    </div>
  );
}
