import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./index.css";
import "@/index.css";
import data from "../data.json";
import { RoomSection } from "../roomSection";
import { HTitle } from "@/components/HTitle";
import { useHotelStore } from "@/stores/hotel";
import { useEffect, useState } from "react";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";
export const HotelDetail = () => {
  const { image, images_facility } = data;
  const { hotel } = useHotelStore();
  const [imgs, setImgs] = useState<string[]>([]);
  const { loading } = useLoadingStore();

  useEffect(() => {
    const imgsArr = hotel?.fields.urls.split(",");
    setImgs(imgsArr ?? []);
  }, []);

  return (
    <div className="hotel_container">
      <div className="hotel_section room_detail">
        <div className="menu-section">
          <a href="#room-section" className="item scroll-to-target">
            Phòng có sẵn
          </a>
          <a href="#quick-convenient-section" className="item scroll-to-target">
            Tiện nghi
          </a>
          <a href="#policy-section" className="item scroll-to-target">
            Chính sách
          </a>
        </div>

        <div className="hotel_section__carousel_container">
          {!loading ? (
            <Carousel infiniteLoop={true}>
              {imgs.map((slideImage, index) => {
                return (
                  <div key={index} className="img-container">
                    <img src={slideImage} alt="" />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <Skeleton width={800} height={600} />
          )}
        </div>
      </div>

      <section
        className="quick-convenient-section box-section-white room_detail"
        id="quick-convenient-section"
      >
        <HTitle>Tiện nghi chổ nghỉ</HTitle>
        <div className="list-convenient">
          {!loading ? (
            images_facility.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div className="box-img-flex">
                    <img src={item.url} alt="Hình ảnh  Ti vi" />
                  </div>
                  <div className="text">{item.title}</div>
                </div>
              );
            })
          ) : (
            <div
              className="list-convenient"
              style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Skeleton width={"1000px"} height={"100px"} />
            </div>
          )}
        </div>
        <div className="text-center">
          <a
            href="#convenient-section"
            className="btn-convenient scroll-to-target title-main-4"
          >
            Xem tất cả các tiện ích
          </a>
        </div>
      </section>

      <RoomSection />
    </div>
  );
};
