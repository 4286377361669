import { useLoadingStore } from "@/stores/global";
import "./index.css";
import Skeleton from "react-loading-skeleton";
export const Policies = () => {
  const { loading } = useLoadingStore();
  return (
    <>
      <section
        className="policy-section box-section-white hotel_section room_detail"
        id="policy-section"
        style={{
          width: "80%",
        }}
      >
        {!loading ? (
          <div className="list-policy">
            <div className="item-policy">
              <div className="title">Chính sách khách sạn</div>
              <div className="desc t-text-editor">
                <div>
                  - Không cho phép vật nuôi
                  <br />- Giường phụ cho trẻ từ 12 tuổi trở lên nếu yêu cầu và
                  có tính phí{" "}
                </div>
              </div>
            </div>

            <div className="item-policy">
              <div className="title">Nhận trả phòng</div>
              <div className="desc">
                Nhận phòng: <b>02:00 PM</b> - Trả phòng: <b>12:00 AM</b>
              </div>
            </div>
          </div>
        ) : (
          <Skeleton width={"100%"} height={"150px"} />
        )}
      </section>
    </>
  );
};
