import { useNavigate, useParams } from "react-router-dom";
import { ContentLayout } from "@/components/Layout";
import data from "./data.json";
import Heading from "./heading";
import Booking from "./booking";
import Content from "./content";
import SlideShow from "./slideShow";
import "./index.css";
import { useAttractionStore } from "@/stores/attraction";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import Loading from "@/components/Loading";
import { useLoadingStore } from "@/stores/global";

export default function () {
  const { title } = useParams();

  const { setAttraction } = useAttractionStore();
  const { setLoading, loading } = useLoadingStore();
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await axios.get(URL_BE + "/attractions/" + title);
      setAttraction(result.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ContentLayout cs={"width-[100%] flex justify-center attraction"}>
        <div id="attraction_wrapper">
          <Heading />
          <div id="attraction_content">
            <div
              className="attraction_body"
              style={loading ? { width: "70%" } : {}}
            >
              <SlideShow />
              <Content />
            </div>
            <Booking />
          </div>
        </div>
      </ContentLayout>
    </>
  );
}
