import { ContentLayout } from "@/components/Layout";
import "./index.css";
import CustomerInfomation from "./customer_infomation";
import Policies from "./policies";
import Booking from "./booking";
import { useLocation, useNavigate } from "react-router-dom";
import { INVOICE, URL_BE } from "@/constants/route";
import { useEffect, useState } from "react";
import { IHotel } from "@/interface";
import { usePaymentStore } from "@/stores/payment";
import axios from "axios";
import Loading from "@/components/Loading";
import { useForm } from "react-hook-form";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  getAttraction,
  getHotel,
  getOffer,
  getProduct,
  getTour,
  setInvoiceId,
  setInvoiceType,
} from "@/components/localStorage";
import { useAttractionStore } from "@/stores/attraction";
import { ToastContainer, toast } from "react-toastify";
import { fieldInvoice } from "@/types/props";
import { TypeIParams, TypeStatus } from "@/types/enums";
import { useAuthStore } from "@/stores/auth";
import { getUserProfile } from "@/components/Function";

const TAB_LIST = ["tab1", "tab2"];

interface IUserInfo {
  email: string;
  access_token: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export default function () {
  const navigate = useNavigate();

  let { search } = useLocation();
  const {
    setField,
    params,
    email,
    first_name,
    last_name,
    phone_number,
    isLoading: loading,
    setContactInfo,
    customerInfo,
  } = usePaymentStore();

  const { token } = useAuthStore();

  const [tab, setTab] = useState<string>(TAB_LIST[0]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [item, setItem] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  // Tour

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserInfo>();

  useEffect(() => {
    if (search.indexOf(TypeIParams.FAST_BUY) > -1) {
      setItem(JSON.parse(getAttraction()));
    }

    const items = search.replaceAll("?", "").split("&");
    const object: any = {};
    for (const _key in items) {
      const key = items[_key].split("=")[0];
      const value = items[_key].split("=")[1];
      object[key] = value;
    }
    setField({ ...object });
  }, []);

  useEffect(() => {
    const { email, first_name, last_name, phone_number } = getUserProfile();
    setMail(email ?? "");
    setFirstName(first_name ?? "");
    setLastName(last_name ?? "");
    setPhoneNumber(phone_number ?? "");

    if (email && first_name && last_name && phone_number) {
      setContactInfo({
        email,
        first_name,
        last_name,
        phone_number,
      });
    }
  }, [token]);

  const onNavigateTab = (tab: string) => {
    setTab(tab);
  };

  const inputContacts = (key: string, value: string) => {
    const oldVal = {
      email,
      first_name,
      last_name,
      phone_number,
    };
    const object = JSON.parse(`{"${key}":"${value}"}`);
    setContactInfo({ ...oldVal, ...object });
  };

  const minDate = (): string => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const onClick = async (data: any) => {
    setIsLoading(true);
    try {
      if (params) {
        // Attraction
        if (params.type === TypeIParams.ATTRACTION) {
          const info = JSON.parse(getAttraction() ?? "");

          delete info?.description;
          const value: fieldInvoice = {
            customer_info: "",
            adults: params.adults,
            type: params.type,
            email: email,
            date_buy: minDate(),
            date_check_in: params.time,
            record_id: params.id,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            kid: params.kid,
            older: params.older,
            product_json: JSON.stringify(info) ?? "",
            status: TypeStatus.NOT_DONE,
          };
          const result = await axios.post(URL_BE + "/invoice/", value);
          setInvoiceId(result.data.id);
          setTimeout(() => {
            navigate(INVOICE + result.data.id);
          }, 1000);

          // Fast_buy
        } else if (params.type === TypeIParams.FAST_BUY) {
          const adults = item?.Name.indexOf("Người lớn") > -1 ? 1 : 0;
          const kid = item?.Name.indexOf("Trẻ em") > -1 ? 1 : 0;
          const older = item?.Name.indexOf("Người cao tuổi") > -1 ? 1 : 0;
          const value: fieldInvoice = {
            adults: adults.toString(),
            type: params.type,
            customer_info: "",
            email: email,
            date_buy: minDate(),
            date_check_in: params.time,
            record_id: params.id,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            kid: kid.toString(),
            older: older.toString(),
            product_json: JSON.stringify(item) ?? "",
            status: TypeStatus.NOT_DONE,
          };
          const result = await axios.post(URL_BE + "/invoice/", value);
          setInvoiceId(result.data.id);
          setInvoiceType(TypeIParams.FAST_BUY);
          setTimeout(() => {
            navigate(INVOICE + result.data.id);
          }, 1000);

          // Hotel
        } else if (params.type === TypeIParams.HOTEL) {
          const hotel: IHotel = JSON.parse(getHotel() ?? "");
          const value: fieldInvoice = {
            adults: "0",
            customer_info: "",
            type: params.type,
            email: email,
            date_buy: minDate(),
            date_check_in: params.time,
            record_id: params.id,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            kid: "0",
            older: "0",
            product_json: JSON.stringify(hotel) ?? "",
            status: TypeStatus.NOT_DONE,
          };
          const result = await axios.post(URL_BE + "/invoice/", value);
          setInvoiceType(TypeIParams.HOTEL);
          setInvoiceId(result.data.id);
          setTimeout(() => {
            navigate(INVOICE + result.data.id);
          }, 1000);

          // Tour
        } else if (params.type === TypeIParams.TOUR) {
          const isFilled = customerInfo.every((item: any, index: number) => {
            return (
              item[`customer_number_${index + 1}`] !== "" &&
              item[`identity_card_${index + 1}`] !== "" &&
              item[`year_number_${index + 1}`] !== ""
            );
          });

          if (!isFilled) {
            toast.error("Điền thiếu trường thông tin khách hàng!");
            return;
          }

          const info = JSON.parse(getTour() ?? "");

          delete info?.description;
          const value: fieldInvoice = {
            adults: "0",
            type: params.type,
            email: email,
            date_buy: minDate(),
            date_check_in: params.time,
            record_id: params.id,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            kid: "0",
            older: "0",
            customer_info: JSON.stringify(customerInfo),
            product_json: JSON.stringify(info) ?? "",
            status: TypeStatus.NOT_DONE,
          };
          const result = await axios.post(URL_BE + "/invoice/", value);
          setInvoiceId(result.data.id);
          setTimeout(() => {
            navigate(INVOICE + result.data.id);
          }, 1000);

          // Offer
        } else if (params.type === TypeIParams.OFFER) {
          const isFilled = customerInfo.every((item: any, index: number) => {
            return (
              item[`customer_number_${index + 1}`] !== undefined &&
              item[`identity_card_${index + 1}`] !== undefined &&
              item[`year_number_${index + 1}`] !== undefined
            );
          });
          if (!isFilled) {
            toast.error("Điền thiếu trường thông tin khách hàng!");
            return;
          }

          const info = JSON.parse(getOffer() ?? "");

          delete info?.description;
          const value: fieldInvoice = {
            adults: "0",
            type: params.type,
            email: email,
            date_buy: minDate(),
            date_check_in: params.time,
            record_id: params.id,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            kid: "0",
            older: "0",
            customer_info: JSON.stringify(customerInfo),
            product_json: JSON.stringify(info) ?? "",
            status: TypeStatus.NOT_DONE,
          };
          const result = await axios.post(URL_BE + "/invoice/", value);
          setInvoiceId(result.data.id);
          setTimeout(() => {
            navigate(INVOICE + result.data.id);
          }, 1000);
        }
      }
    } catch (error) {
      toast.error(
        "Có lỗi xảy ra, Quý Khách vui lòng liên hệ nhân viên kỹ thuật để được hỗ trợ!"
      );
    }
    setIsLoading(false);
  };
  return (
    <ContentLayout cs={"payment-page__wrapper"}>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="form-container">
          <div className="payment-page__container">
            <div className="payment-page__content">
              <div id="payment__body__personal-infomation">
                <div className="heading">
                  <h2>Thông tin cá nhân</h2>
                </div>
                <Tabs>
                  <TabList className={"payment_tablist click"}>
                    <Tab
                      onClick={() => onNavigateTab(TAB_LIST[0])}
                      className={"payment_tabitem"}
                    >
                      Nhập thông tin chi tiết của bạn
                    </Tab>
                  </TabList>

                  <TabPanel className={"payment_tab-item"}>
                    <form className="tab-panel__container">
                      <div
                        className="guest_info"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {firstName ? (
                          <input
                            type="text"
                            placeholder="Họ"
                            {...register("first_name")}
                            value={firstName}
                            readOnly
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Họ"
                            {...register("first_name", {
                              required: tab === TAB_LIST[0],
                            })}
                            onChange={(e: any) => {
                              inputContacts("first_name", e.target.value);
                            }}
                          />
                        )}
                        {errors.first_name && (
                          <p style={{ color: "red" }}>
                            Trường họ tên là bắt buộc.
                          </p>
                        )}
                      </div>
                      <div
                        className="guest_info"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {lastName ? (
                          <input
                            type="text"
                            placeholder="Tên"
                            {...register("last_name")}
                            value={lastName}
                            readOnly
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Tên"
                            {...register("last_name", {
                              required: tab === TAB_LIST[0],
                            })}
                            onChange={(e: any) => {
                              inputContacts("last_name", e.target.value);
                            }}
                          />
                        )}
                        {errors.last_name && (
                          <p style={{ color: "red" }}>
                            Trường tên là bắt buộc.
                          </p>
                        )}
                      </div>
                      <div
                        className="guest_info"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {mail ? (
                          <input
                            type="email"
                            placeholder="Email"
                            {...register("email")}
                            value={mail}
                            readOnly
                          />
                        ) : (
                          <input
                            type="email"
                            placeholder="Email"
                            {...register("email", {
                              required: tab === TAB_LIST[0],
                            })}
                            onChange={(e: any) => {
                              inputContacts("email", e.target.value);
                            }}
                          />
                        )}
                        {errors.email && (
                          <p style={{ color: "red" }}>
                            Trường email là bắt buộc.
                          </p>
                        )}
                      </div>
                      <div
                        className="guest_info"
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {phoneNumber ? (
                          <input
                            type="text"
                            placeholder="Số điện thoại"
                            required={tab === TAB_LIST[0]}
                            {...register("phone_number")}
                            value={phoneNumber}
                            readOnly
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Số điện thoại"
                            required={tab === TAB_LIST[0]}
                            {...register("phone_number", {
                              required: tab === TAB_LIST[0],
                            })}
                            onChange={(e: any) => {
                              inputContacts("phone_number", e.target.value);
                            }}
                          />
                        )}
                        {errors.phone_number && (
                          <p style={{ color: "red" }}>
                            Trường số điện thoại là bắt buộc.
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          bottom: 10,
                          left: "0%",
                        }}
                      >
                        <input
                          className="click"
                          type="submit"
                          disabled={isLoading}
                          onClick={
                            mail && firstName && lastName && phoneNumber
                              ? () => onClick(123)
                              : handleSubmit(onClick)
                          }
                          style={{
                            backgroundColor: isLoading
                              ? "rgba(0, 0, 0, 0.5)"
                              : "#3795fa",
                            color: "white",
                            padding: "10px 10px",
                            borderRadius: 8,
                            width: "150px",
                          }}
                          value={"xác nhận"}
                        />
                      </div>
                    </form>
                  </TabPanel>
                </Tabs>
              </div>
              {(params && params.type === TypeIParams.TOUR) ||
              (params && params.type === TypeIParams.OFFER) ? (
                <CustomerInfomation />
              ) : (
                <></>
              )}
              <Policies />
            </div>
            <div className="payment-page__booking">
              <Booking />
            </div>
          </div>
        </div>
      )}
    </ContentLayout>
  );
}
