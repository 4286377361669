import { Button } from "@/components/Button";
import { Input } from "@/components/Inputs";
import {
  setAttraction,
  setFastBuyTicket,
  setHotel,
  setOffer,
  setProduct,
  setTour,
} from "@/components/localStorage";
import { PAYMENT } from "@/constants/route";
import { useOfferStore } from "@/stores/offer";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

export const SideBar = () => {
  const { loading, offer } = useOfferStore();
  const navigate = useNavigate();
  const [date, setDate] = useState<string>();
  const minDate = (): string => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const onChangeDate = (e: any) => {
    setDate(e.target.value);
  };

  return (
    <div className="offer-sidebar-container">
      <div className="p-4 border-2 offer-sidebar-wrapper">
        {!loading ? (
          <>
            <div className="heading">
              <img src={offer?.url} alt="" />
              <h2 className="title" style={{ margin: "10px 0" }}>
                Đặt dịch vụ để trải nghiệm
              </h2>
              <h2 className="total" style={{ margin: "10px 0" }}>
                Tổng cộng
              </h2>
              <h2 className="price" style={{ margin: "10px 0" }}>
                {offer?.price} vnd
              </h2>
            </div>
            <div
              className={"width-[100%]"}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              <label className={"date"} htmlFor={"date"}>
                Ngày check-in
              </label>
              <input
                className={"border-2 rounded-md mt-1 mb-2 block width-[100%]"}
                type="date"
                name="date"
                min={minDate()}
                defaultValue={minDate()}
                onChange={onChangeDate}
                style={{ width: 150 }}
              />
            </div>

            <Button
              backgroundColor="#ff5e1f"
              title="Đặt ngay"
              csContainer="width-[80%]"
              csBtn="width-[100%]"
              onClick={() => {
                setOffer(JSON.stringify(offer));
                navigate(
                  PAYMENT +
                    "params?type=offer&time=" +
                    (date ? date : minDate())
                );
              }}
            />
          </>
        ) : (
          <Skeleton width={"250px"} height={"500px"} />
        )}
      </div>
    </div>
  );
};
