import { HTitle } from "@/components/HTitle";
import { UText } from "@/components/UText";
import "./index.css";
import { useAttractionStore } from "@/stores/attraction";
import { useEffect, useState } from "react";
import moment from "moment";
import { PAYMENT } from "@/constants/route";
import { useNavigate } from "react-router-dom";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";

type propTypes = {
  price: string;
  currency: string;
};

type personTypes = {
  kid: number;
  adult: number;
  older: number;
};

enum personEnum {
  KID = "kid",
  ADULT = "adult",
  OLDER = "older",
}
export default function () {
  const navigate = useNavigate();
  const { attraction } = useAttractionStore();
  const [price, setPrice] = useState<number>(0);
  const [kidInput, setKid] = useState<number>(0);
  const [olderInput, setOlder] = useState<number>(0);
  const [adultInput, setAdult] = useState<number>(1);
  const { loading } = useLoadingStore();
  const [person, setPerson] = useState<personTypes>({
    kid: 0,
    adult: 1,
    older: 0,
  });
  const [date, setDate] = useState<string>("");
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setPrice(parseInt(attraction?.fields.price.replaceAll(",", "") ?? "0"));
    setTotal(parseInt(attraction?.fields.price.replaceAll(",", "") ?? "0"));
  }, [attraction]);

  const onChangePerson = (typePerson: personEnum, amount: number) => {
    switch (typePerson) {
      case personEnum.ADULT:
        const adult = { adult: amount };
        setPerson({ ...person, ...adult });
        break;
      case personEnum.KID:
        const kid = { kid: amount };
        setPerson({ ...person, ...kid });
        break;
      case personEnum.OLDER:
        const older = { older: amount };
        setPerson({ ...person, ...older });
        break;
    }
  };

  const minDate = (): string => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setDate(newDate);
  };

  useEffect(() => {
    setDate(minDate());
  }, []);

  useEffect(() => {
    let amount = 0;
    for (const key in person) {
      //@ts-ignore
      amount += person[key];
    }
    setTotal(
      amount * parseInt(attraction?.fields.price.replaceAll(",", "") ?? "0")
    );
  }, [person]);

  const submit = () => {
    localStorage.setItem("attraction", JSON.stringify(attraction));
    if (attraction) {
      navigate(
        PAYMENT +
          attraction.id +
          `?adults=${adultInput}&older=${olderInput}&kid=${kidInput}&time=${date}&id=${attraction.id}&type=attraction`
      );
    }
  };

  return (
    <div className="w-[100%]" style={loading ? { width: "30%" } : {}}>
      {!loading ? (
        <div className="booking_container">
          <div className="booking_container__heading">
            <h2>Đặt Vé</h2>
          </div>
          <div className="booking_container__body">
            <div className="booking_by_date">
              <label htmlFor="booking_by_date">Đặt vé cho ngày:</label>
              <form action="">
                <input
                  type="date"
                  name="booking_by_date"
                  min={minDate()}
                  onChange={onChangeDate}
                  defaultValue={minDate()}
                />
              </form>
            </div>

            <form action="" id="booking_container__submit_form">
              <span className="label">Người lớn (1m40)</span>
              <div className="booking_form_item booking_for_adult">
                <input
                  type="number"
                  defaultValue={adultInput}
                  onChange={(e: any) => {
                    if (parseInt(e.target.value) >= 0) {
                      setAdult(parseInt(e.target.value));
                      onChangePerson(
                        personEnum.ADULT,
                        parseInt(e.target.value)
                      );
                    }
                  }}
                />{" "}
                /{"\t"}
                <span className="price">{attraction?.fields.price} VND</span>
              </div>

              <span className="label">Trẻ em 5 - 12 (1m - dưới 1m4)</span>
              <div className="booking_form_item booking_for_kid">
                <input
                  type="number"
                  defaultValue={kidInput}
                  onChange={(e: any) => {
                    if (parseInt(e.target.value) >= 0) {
                      setKid(parseInt(e.target.value));
                      onChangePerson(personEnum.KID, parseInt(e.target.value));
                    }
                  }}
                />{" "}
                /{"\t"}
                <span className="price">{attraction?.fields.price} VND</span>
              </div>

              <span className="label">Người cao tuổi (60 tuổi)</span>
              <div className="booking_form_item booking_for_older">
                <input
                  type="number"
                  defaultValue={olderInput}
                  onChange={(e: any) => {
                    if (parseInt(e.target.value) >= 0) {
                      setOlder(parseInt(e.target.value));
                      onChangePerson(
                        personEnum.OLDER,
                        parseInt(e.target.value)
                      );
                    }
                  }}
                />{" "}
                /{"\t"}
                <span className="price">{attraction?.fields.price} VND</span>
              </div>
              <div className="booking_order">
                <div className="total">
                  <h3>Tổng tiền</h3>
                </div>
                <div className="price">{total.toLocaleString()} VND</div>
                <div className="fees_advance">
                  <UText>Trả trước {total.toLocaleString()} VND</UText>
                </div>
              </div>
              <div className="order_submit">
                <input type="submit" value="Đặt ngay" onClick={submit} />
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Skeleton width={"100%"} height={"500px"} />
      )}
    </div>
  );
}
