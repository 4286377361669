import { useLoadingStore } from "@/stores/global";
import "./index.css";
import Skeleton from "react-loading-skeleton";
export default function () {
  const { loading } = useLoadingStore();
  return (
    <div className="tour_detail__body__policies tour_container">
      <span className="title">QUY ĐỊNH CHUNG</span>
      {!loading ? (
        <div className="policies_body">
          <div className="row_policies">
            <div className="row_policies_left-box">
              <span>Chính sách Tour</span>
            </div>
            <div className="row_policies_right-box"></div>
          </div>
          <div className="row_policies">
            <div className="row_policies_left-box">
              <span>Phương thức thanh toán</span>
            </div>
            <div className="row_policies_right-box"></div>
          </div>
          <div className="row_policies">
            <div className="row_policies_left-box">
              <span>Hành trình tour</span>
            </div>
            <div className="row_policies_right-box">Lịch Sử / Văn Hóa</div>
          </div>
        </div>
      ) : (
        <Skeleton width={"100%"} height={"100px"} />
      )}
    </div>
  );
}
