import { HTitle } from "@/components/HTitle";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import data from "../data.json";
import { useEffect, useState } from "react";
import { NULL_DATA, URL_BE } from "@/constants/route";
import axios from "axios";
import { IHotel } from "@/interface";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";

export const Hotels = () => {
  const { images_facility } = data;
  const navigate = useNavigate();
  const { title } = useParams();
  const [hotels, setHotels] = useState<IHotel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [location, setLocation] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const result = await axios.get(
        URL_BE + "/hotels/hotel-by-category/" + title + "/" + page
      );
      setLocation(result.data.cur_items[0].fields.location);
      setTotal(result.data.total_items);
      setHotels(result.data.cur_items);
      setTotalPages(result.data.total_pages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [title]);

  const onclick = (title: string) => {
    navigate("/hotels/hotel/" + title);
  };

  return (
    <>
      <div
        className="content-left col-lg-9 prod-lst"
        style={{ minHeight: "800px", paddingTop: 150 }}
      >
        <section className="box-section-white result-section">
          <div className="number-result">
            {!loading ? (
              <>
                <span>{total}</span> {"kết quả được tìm thấy"}
              </>
            ) : (
              <Skeleton width={200} />
            )}
          </div>
        </section>
        <section className="box-section-white">
          <div className="list-hotel width-[100%]">
            {(!loading ? hotels : NULL_DATA).map((item, index) => {
              return (
                <div className="flex mt-4 mb-4" key={index}>
                  <div
                    className="box-img mr-4"
                    onClick={item ? () => onclick(item.id) : () => {}}
                  >
                    {item ? (
                      <img
                        src={item.fields.url}
                        alt="Seashells Phu  Quoc Hotel &amp; Spa"
                      />
                    ) : (
                      <Skeleton width={200} height={150} />
                    )}
                  </div>
                  <div className="box-content">
                    <div className="box-left">
                      <div className="top-content">
                        <button
                          onClick={item ? () => onclick(item.id) : () => {}}
                          className="mb-4"
                        >
                          <HTitle styles={{ fontSize: 20 }}>
                            {item ? (
                              item.fields.title.trim()
                            ) : (
                              <Skeleton width={100} height={20} />
                            )}
                          </HTitle>
                        </button>
                        <div className="address-hotel">
                          <span className="input-group-icon icon-location"></span>
                          {item ? (
                            <>
                              <strong>Địa điểm:</strong> {item.fields.location}
                            </>
                          ) : (
                            <Skeleton width={200} height={20} />
                          )}{" "}
                        </div>

                        <div className="list_room__item-room">
                          <div className="list-endow">
                            {item ? (
                              item.fields.contains
                                .split(",")
                                .map((item: string, index: number) => {
                                  return (
                                    <div className="item" key={index}>
                                      <div className="text">
                                        - {item.replaceAll('"', "")}
                                      </div>
                                    </div>
                                  );
                                })
                            ) : (
                              <Skeleton width={200} height={20} />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="list-icon space-x-2">
                        {images_facility.map((item, index) => {
                          return (
                            <div className="item box-img-flex" key={index}>
                              <img
                                src={item.url}
                                title={item.title}
                                data-toggle="tooltip"
                                data-placement="top"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="box-right">
                      <div className="box-price item-room">
                        <div className="price-room">
                          {item ? (
                            <div
                              className="price"
                              style={{
                                color:
                                  parseInt(
                                    item ? item.fields.spare_room : "0"
                                  ) > 0
                                    ? "#ff5e1f"
                                    : "rgba(0,0,0,0.4)",
                              }}
                            >
                              {parseInt(item.fields.spare_room) > 0
                                ? "Còn phòng"
                                : "Hết phòng"}
                            </div>
                          ) : (
                            <Skeleton width={200} height={20} />
                          )}
                        </div>
                        {!loading ? (
                          <p
                            onClick={item ? () => onclick(item.id) : () => {}}
                            className="click"
                          >
                            Chi tiết
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <ReactPaginate
              breakLabel="..."
              nextLabel="Trang tiếp theo >"
              onPageChange={(event) => {
                fetchData(event.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="< Trang trước"
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          <div className="text-center"></div>
        </section>
      </div>
    </>
  );
};
