import { IAttractionReturn } from "@/interface";
import { fieldTicket } from "@/types/props";
import create from "zustand";

export type attraction = {
  fieldAttraction: IAttractionReturn[] | [];
  attraction: IAttractionReturn | null;
  tickets: fieldTicket[] | [];
  ticket: fieldTicket | null;
  setField: (value: IAttractionReturn[]) => void;
  setAttraction: (value: IAttractionReturn) => void;
  setTickets: (value: fieldTicket[]) => void;
  setTicket: (value: fieldTicket) => void;
};

export const useAttractionStore = create<attraction>((set) => ({
  fieldAttraction: [],
  attraction: null,
  ticket: null,
  tickets: [],
  setField: (data) => set((state) => ({ fieldAttraction: data })),
  setAttraction: (data) => set((state) => ({ attraction: data })),
  setTickets: (data) => set((state) => ({ tickets: data })),
  setTicket: (data) => set((state) => ({ ticket: data })),
}));
