import create from "zustand";

export type loading = {
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export const useLoadingStore = create<loading>((set) => ({
  loading: false,
  setLoading: (data) => set((state) => ({ loading: data })),
}));
