import React, { useEffect, useState } from "react";
import "./index.css";
import { useInvoiceStore } from "@/stores/invoice";

export default function () {
  const { invoice } = useInvoiceStore();
  const [total, setTotal] = useState<number>(0);
  const [info, setInfo] = useState<any>([]);

  useEffect(() => {
    console.log(invoice);
    if (invoice?.customer_info) {
      setInfo(JSON.parse(invoice?.customer_info));
    }

    const adults = parseInt(invoice?.adults ?? "0");
    const kid = parseInt(invoice?.kid ?? "0");
    const olders = parseInt(invoice?.older ?? "0");

    setTotal(adults + kid + olders);
  }, []);
  return (
    <div id="info_customer-tour_container">
      <h2>thông tin đoàn khách</h2>
      <div className="box-container">
        {info.map((item: any, index: number) => {
          return (
            <div className="box-item" key={index}>
              <div className="left-box">
                <span>{item[`customer_number_` + (index + 1)]}</span>
              </div>
              <div className="center-box">
                <span>{item[`identity_card_` + (index + 1)]}</span>
              </div>
              <div className="right-box">
                <span>{item[`year_number_` + (index + 1)]}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
