import { ContentLayout } from "@/components/Layout";
import { Hotels } from "./hotelList";

export default function () {
  return (
    <ContentLayout>
      <Hotels />
    </ContentLayout>
  );
}
