import React, { useEffect, useState } from "react";
import "./index.css";

import { MER_ID, ENCODE_KEY } from "@/constants/route";
import { sha256 } from "js-sha256";
import moment from "moment";
import { useInvoiceStore } from "@/stores/invoice";
import {
  getAttraction,
  getFastBuyTicket,
  getHotel,
  getInvoiceId,
  getInvoiceType,
  getOffer,
  getRoom,
  getTour,
} from "@/components/localStorage";
import { useLocation } from "react-router-dom";
import { TypeIParams } from "@/types/enums";

type fieldAttraction = {
  title: string;
  location: string;
  price: string;
  number_locates: number;
  url: string;
  opening_time: string;
};

export default function () {
  const [merchantToken, setMerchantToken] = useState<string>("");
  const [merId, setMerID] = useState<string>("");
  const [invoiceNo, setInvoiceNo] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const { invoice } = useInvoiceStore();
  const [product, setProduct] = useState<fieldAttraction | null>(null);
  const [goodsNm, setGoodsNm] = useState<string>("");
  const [room, setRoom] = useState<any>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  let { search, pathname } = useLocation();

  function generateString(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  useEffect(() => {
    const type = getInvoiceType();
    const strHotel = getHotel();
    const fastBuy = getFastBuyTicket();
    let merTrxId = "";
    const timeStamp = moment().format("YYYYMMDDHHmmss");
    let amountNumber = 0;
    let invoiceId = getInvoiceId();

    // hotel
    if (type === TypeIParams.HOTEL) {
      const hotel = JSON.parse(strHotel);
      merTrxId = MER_ID + generateString(10) + "_" + invoiceId;
      setInvoiceNo(merTrxId);
      const room = JSON.parse(hotel.fields.room_json).fields;
      amountNumber = room.price.replaceAll(",", "");
      setAmount(room.price.replaceAll(",", ""));
      setGoodsNm(room.title);

      // Fast_buy
    } else if (type === TypeIParams.FAST_BUY) {
      const item = JSON.parse(fastBuy);
      setGoodsNm(item.Name);

      amountNumber = item.Price;
      merTrxId = MER_ID + generateString(10);
      setInvoiceNo(merTrxId);
      setAmount(item.Price.toString());

      // Tour
    } else if (type === TypeIParams.TOUR) {
      const tour = JSON.parse(getTour());
      merTrxId = MER_ID + generateString(10) + "_" + invoiceId;
      setInvoiceNo(merTrxId);
      amountNumber = tour.fields.price.replaceAll(",", "").trim();
      setAmount(tour.fields.price.replaceAll(",", "").trim());
      setGoodsNm(tour.fields.title);

      // Offer
    } else if (type === TypeIParams.OFFER) {
      const offer = JSON.parse(getOffer());
      merTrxId = MER_ID + generateString(10) + "_" + invoiceId;
      setInvoiceNo(merTrxId);
      amountNumber = offer.fields.price.replaceAll(",", "").trim();
      setAmount(offer.fields.price.replaceAll(",", "").trim());
      setGoodsNm(offer.fields.title);
    } else if (type === TypeIParams.ATTRACTION) {
      const attraction = JSON.parse(getAttraction());
      merTrxId = MER_ID + generateString(10) + "_" + invoiceId;
      setInvoiceNo(merTrxId);
      amountNumber = attraction.fields.price.replaceAll(",", "").trim();
      setAmount(attraction.fields.price.replaceAll(",", "").trim());
      setGoodsNm(attraction.fields.title);
    }
    setMerID(merTrxId);
    const result = sha256(
      timeStamp + merTrxId + MER_ID + amountNumber + ENCODE_KEY
    );
    setMerchantToken(result);
  }, [invoice]);

  return (
    <div className="form_container">
      <form id="megapayForm" name="megapayForm" method="post">
        <input
          type="hidden"
          name="invoiceNo"
          value={invoice?.record_id + moment().format("YYYYMMDDHHmmss")}
        />
        <input type="hidden" name="amount" value={amount ?? ""} />
        <input type="hidden" name="currency" value="VND" />
        <input type="hidden" name="goodsNm" value={goodsNm} />
        <input type="hidden" name="fee" value="0" />

        <input
          type="hidden"
          name="buyerFirstNm"
          value={invoice?.first_name ?? ""}
        />
        <input
          type="hidden"
          name="buyerLastNm"
          value={invoice?.last_name ?? ""}
        />
        <input
          type="hidden"
          name="buyerPhone"
          value={invoice?.phone_number ?? ""}
        />
        <input type="hidden" name="buyerEmail" value={invoice?.email ?? ""} />
        <input
          type="hidden"
          name="buyerInvoiceId"
          value={invoice?.invoice_id ?? ""}
        />
        <input
          type="hidden"
          name="callBackUrl"
          value="http://localhost:3001/api/payment/callback/transaction"
        />
        <input
          type="hidden"
          name="notiUrl"
          value="https://demo.megapay.vn/ipn/transactionHandle"
        />
        <input type="hidden" name="merId" value={MER_ID} />
        <input type="hidden" name="reqDomain" value="http://localhost:3001" />
        <input type="hidden" name="userId" value="0" />
        <input type="hidden" name="userLanguage" value="VN" />
        <input type="hidden" name="merchantToken" value={merchantToken} />
        <input type="hidden" name="payToken" value="" />
        <input
          type="hidden"
          name="timeStamp"
          value={moment().format("YYYYMMDDHHmmss")}
        />
        <input type="hidden" name="merTrxId" value={merId ?? ""} />
        <input type="hidden" name="windowType" value="0" />
        <input type="hidden" name="windowColor" value="#ef5459" />
        <input type="hidden" name="userFee" value="" />
        <input type="hidden" name="vaCondition" value="03" />
        <input type="hidden" name="payType" value="NO" />
        <input type="hidden" name="payOption" value="" />
        <input
          type="hidden"
          name="vaStartDt"
          value={moment().format("YYYYMMDDHHmmss")}
        />
        <input type="hidden" name="vaEndDt" value="20240215235959" />
        <input type="hidden" name="bankCode" value="" />
        <input
          type="hidden"
          name="description"
          value={invoice?.email + "_" + invoice?.invoice_id}
        />
      </form>

      <button
        style={{
          fontSize: 16,
          backgroundColor: "rgb(46, 144, 250)",
          color: "white",
          padding: "10px 16px",
          borderRadius: 8,
        }}
        onClick={() => {
          {
            /* @ts-ignore */
            openPayment(1, "https://sandbox.megapay.vn");
          }
        }}
      >
        Thanh toán
      </button>
    </div>
  );
}
