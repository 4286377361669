import React from "react";
import { AppRoutes } from "./routes";
import "react-slideshow-image/dist/styles.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return <AppRoutes />;
}

export default App;
