import { HTitle } from "@/components/HTitle";
import { URL_BE } from "@/constants/route";
import { useOfferStore } from "@/stores/offer";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
export const Content = () => {
  const { offer, loading } = useOfferStore();
  const [description, setDescription] = useState<string>("");
  const [name, setName] = useState<string>("");

  useEffect(() => {
    let htmlDes = "";
    let name = "";

    if (offer) {
      htmlDes = offer.description.replaceAll("class", "className");
      name = offer.title;
    }
    setDescription(htmlDes);
    setName(name);
  }, [offer]);

  const styles = () => (
    <>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/owl.carousel.min.css"
      ></link>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/customize.css"
      ></link>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/css/main.css"
      ></link>

      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/all.min.css?v=1693898936"
      ></link>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/slick.css?v=1693898936"
      ></link>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/slick-theme.css?v=1693898936"
      ></link>
      <link
        rel="stylesheet"
        href="https://gophuquoc.net/themes/gpqTheme/assets/vendor/css/combo.css?v=1693898936"
      ></link>
    </>
  );

  return (
    <div
      className="content_offer_body"
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: 16,
        borderRadius: 8,
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      {styles()}
      {!loading ? (
        <HTitle>{name}</HTitle>
      ) : (
        <Skeleton width={"1000px"} height={50} />
      )}
      {!loading ? (
        <div style={{ fontSize: 16 }}>{parse(description)}</div>
      ) : (
        <Skeleton height={"500px"} />
      )}
    </div>
  );
};
