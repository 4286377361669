import "./index.css";
export default function () {
  return (
    <div className="payment__body__policies mt-4">
      <div className="notif_container">
        <span className="notif">
          Vui lòng đọc các điều khoản và chính sách trước khi tiến hành đặt
          tour.
        </span>
        <span className="notif_more">
          Xem thêm các điều khoản và điều kiện từ GoIsland
        </span>
      </div>
      <div className="confirmation_container">
        <span className="confirmation">
          Bằng cách nhấp vào Xác Nhận Đặt, bạn đã đồng ý các Điều Khoản và Chính
          Sách đặt tour này.
        </span>
      </div>
    </div>
  );
}
