import "./index.css";
import { useAttractionStore } from "@/stores/attraction";

export default function () {
  const { attraction } = useAttractionStore();

  return (
    <div className="attraction_container">
      <div className="attraction_heading">
        <div className="box_right">
          <div className="attraction_title">
            <h2>{attraction?.fields.title}</h2>
          </div>
          <div className="attraction_location">
            {attraction?.fields.location}
          </div>
        </div>
        <div className="attraction_info_provider box-left">
          <div className="attraction_info_provider box-item-top">
            <span>
              {attraction?.fields.price} {attraction?.fields.price && "VND"}
            </span>
          </div>
          {attraction?.fields.price && <a href="#detail">Chi tiết</a>}
        </div>
      </div>
    </div>
  );
}
