type HTitleProps = {
  children: React.ReactNode;
  cs?: string;
  styles?: any;
};

export const List = ({ children, cs, styles }: HTitleProps) => {
  return (
    <li style={{ listStyleType: 'circle', ...styles }} className={cs}>
      {children}
    </li>
  );
};
