

type UTextProps = {
  children: React.ReactNode;
  cs?: string;
  styles?: any;
};

export const UText = ({ children, cs, styles }: UTextProps) => {
  return (
    <p style={{ color: '#191919', ...styles }} className={'inline-block ' + cs}>
      {children}
    </p>
  );
};
