export const BASE_GOPHUQUOC_URL = "https://gophuquoc.net/";
export const HOMEPAGE = "/";
export const TICKETS = "/attractions/";
export const TICKET = "/attractions/";
export const HOTELS = "/hotels/";
export const HOTEL = "/hotels/hotel/";
export const OFFER = "/offer/";
export const TOURS = "/tours/";
export const TOURS_DETAIL = "/tours/tour/";
export const PAYMENT = "/payment/";
export const INVOICE = "/invoice/";
export const USER = "/user/";
export const SEARCH_ORDER = "/search-order/";

export const URL_BE = "http://localhost:3001/api";
export const URL_HOMEPAGE = "http://localhost:3000";

export const MER_ID = "EPAY000001";
export const ENCODE_KEY =
  "rf8whwaejNhJiQG2bsFubSzccfRc/iRYyGUn6SPmT6y/L7A2XABbu9y4GvCoSTOTpvJykFi6b1G0crU8et2O0Q==";
export const NULL_DATA = [null, null, null, null, null, null];
