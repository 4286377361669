import * as React from "react";

import { Head } from "@/components/Head";
import { TopBanner } from "@/components/TopBanner";
import { Foot } from "../../Foot";
import "./index.css";

type ContentLayoutProps = {
  children: React.ReactNode;
  cs?: string;
  nonAuth?: boolean;
};

export const ContentLayout = ({
  children,
  cs = "",
  nonAuth,
}: ContentLayoutProps) => {
  return (
    <div className="content-layout__container">
      <Head />
      <div className={`${cs}`} id="content-layout__body">
        {children}
      </div>
      <Foot />
    </div>
  );
};
