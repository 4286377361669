import { dataReturn } from "@/features/users/components/booking";
import create from "zustand";

export type order = {
  type: string;
  typeDone: dataReturn[];
  typeNotDone: dataReturn[];
  setTypeDone: (value: dataReturn[]) => void;
  setTypeNotDone: (value: dataReturn[]) => void;
};

export const useOrderStore = create<order>((set) => ({
  type: "",
  typeDone: [],
  typeNotDone: [],
  setTypeDone: (data) => set((state) => ({ typeDone: [...data] })),
  setTypeNotDone: (data) => set((state) => ({ typeNotDone: [...data] })),
}));
