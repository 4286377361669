type HTitleProps = {
  children: React.ReactNode;
  cs?: string;
  styles?: any;
  onClick?: void;
};

export const HTitle = ({ children, cs, styles, onClick }: HTitleProps) => {
  return (
    <h2
      style={{ color: '#191919', fontSize: 30, fontWeight: 'bold', ...styles }}
      className={cs}
      onClick={onClick ? onClick : () => {}}
    >
      {children}
    </h2>
  );
};
