import "./index.css";
import _data from "../data.json";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useAttractionStore } from "@/stores/attraction";
import { useLoadingStore } from "@/stores/global";
import Skeleton from "react-loading-skeleton";

type policyType = {
  title: string;
  name: string;
  data: string;
};

export default function () {
  const { attraction } = useAttractionStore();
  const [policies, setPolicies] = useState<policyType[]>([]);
  const { loading } = useLoadingStore();

  useEffect(() => {
    if (attraction) {
      for (const key in attraction) {
        if (!isNaN(parseInt(key))) {
          //@ts-ignore
          setPolicies((prev) => [...prev, attraction[key]]);
        }
      }
    }
  }, [attraction]);

  return (
    <>
      <div className="attraction_content_container">
        <div className="attraction_content_section" id="detail">
          {!loading ? (
            parse(attraction?.fields.description ?? "")
          ) : (
            <Skeleton width={"100%"} height={500} />
          )}

          <div id="detail-content-sticky-nav-05">
            <h3 className="heading-title">
              <span>Quy định chung</span>
            </h3>
            <div className="bg-white">
              {!loading ? (
                policies &&
                policies.map((item, index) => {
                  return (
                    <div className="box-item" key={index} id={"policies"}>
                      <h6>{item.name}</h6>
                      <p>{parse(item.data)}</p>
                    </div>
                  );
                })
              ) : (
                <Skeleton width={"400px"} height={100} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
