import { BrowserRouter, Route, Routes } from "react-router-dom";

import { protectedRoutes } from "./protected";
import { publicRouter } from "./public";
import Auth from "@/features/auth/components/index";
import { useAuthStore } from "@/stores/auth";

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { PAYMENT_RESULT_FAIL, PAYMENT_RESULT_SUCCESS } from "@/constants/text";

export const AppRoutes = () => {
  const { token } = useAuthStore();
  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setAuth(true);
    }
  }, []);

  useEffect(() => {
    const url = localStorage.getItem("result") ?? "";
    if (url.indexOf("successed") > -1) {
      toast.success(PAYMENT_RESULT_SUCCESS);
    } else if (url.indexOf("failed") > -1) {
      toast.error(PAYMENT_RESULT_FAIL);
    }
    localStorage.setItem("result", "");
  }, []);

  useEffect(() => {
    const url = window.location.href;
    if (url.indexOf("message=") > -1) {
      localStorage.setItem("result", url.split("=")[1]);
      const newHref = window.location.href
        .replace("?message=successed", "")
        .replace("?message=failed", "");
      window.location.href = newHref;
    }
  }, []);

  return (
    <BrowserRouter>
      <Auth>
        <ToastContainer />
        <Routes>
          {(token || auth) &&
            protectedRoutes.map((route, index) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}
          {publicRouter.map((route) =>
            route ? (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ) : (
              <></>
            )
          )}
        </Routes>
      </Auth>
    </BrowserRouter>
  );
};
