import { IHotel } from "@/interface";
import create from "zustand";

export type hotel = {
  hotel: IHotel | undefined;
  setField: (value: IHotel) => void;
};

export const useHotelStore = create<hotel>((set) => ({
  hotel: undefined,
  setField: (data) => set((state) => ({ hotel: data })),
}));
