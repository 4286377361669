import "./index.css";
import { useAuthStore } from "@/stores/auth";
import { authType } from "@/types/enums";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { URL_BE, USER } from "@/constants/route";
import axios from "axios";
import { toast } from "react-toastify";
import { fieldRegisterForm } from "@/types/props";
import {
  EMAIL_NOT_MATCH,
  PASSWORD_LENGTH,
  PASSWORD_NOT_MATCH,
  PHONE_LENGTH,
} from "@/constants/text";
import { IUserInfo } from "@/interface";
import { saveUserProfile } from "@/components/Function";
import { useNavigate } from "react-router-dom";

export default function () {
  const { loginForm, setType, type, setToken } = useAuthStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<fieldRegisterForm>();

  const password = useRef({});
  password.current = watch("password", "");

  const onNavigationLogin = () => {
    setType(authType.LOGIN);
  };

  const onClose = () => {
    setType(authType.NON_AUTH);
  };

  const submit = async (value: fieldRegisterForm) => {
    setLoading(true);
    try {
      await axios.post(URL_BE + "/auth/signup", value);
      toast.success("Đăng ký thành công");
      onNavigationLogin();
    } catch (error: any) {
      toast.error("Lỗi: " + error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-header">
          <span className="icon-user-edit fs-3"></span>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h4 className="modal-title fw-bold">
            <span>Đăng kí thành viên</span>
          </h4>
          <small className="grey-300-clr">
            Hãy tạo tài khoản mới để trải nghiệm GoPhuQuoc nhé!
          </small>
          <div
            className="alert alert-danger mt-3 result-signup"
            style={{ display: "none" }}
          ></div>
          <form className="mt-4" id="frmNewCustomer" method="POST">
            <div className="row item">
              <div className="item">
                <label htmlFor="first_name" className="form-label">
                  Tên khách hàng
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tên khách hàng"
                  {...register("first_name", { required: true })}
                  name="first_name"
                  style={
                    errors.first_name
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
              <div className="item">
                <label htmlFor="lastname" className="form-label">
                  Họ
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Họ"
                  {...register("last_name", { required: true })}
                  name="last_name"
                  style={
                    errors.last_name
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
              <div className="item">
                <label htmlFor="phone_number" className="form-label">
                  Điện thoại
                </label>
                <input
                  type="phone"
                  className="form-control"
                  placeholder="Điện thoại"
                  {...register("phone_number", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: PHONE_LENGTH,
                    },
                  })}
                  required={true}
                  style={
                    errors.phone_number
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
              <div className="item">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: EMAIL_NOT_MATCH,
                    },
                  })}
                  style={
                    errors.email
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>

              <div className="item">
                <label htmlFor="password" className="form-label">
                  Mật khẩu
                </label>
                <input
                  type="password"
                  autoComplete="on"
                  className="form-control"
                  placeholder="Mật khẩu"
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: PASSWORD_LENGTH,
                    },
                  })}
                  style={
                    errors.password
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
              <div className="item">
                <label htmlFor="pasword_confirm" className="form-label">
                  Xác nhận mật khẩu
                </label>
                <input
                  type="password"
                  autoComplete="on"
                  className="form-control"
                  placeholder="Xác nhận mật khẩu"
                  {...register("pasword_confirm", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: PASSWORD_LENGTH,
                    },
                    validate: (value) =>
                      value === password.current || PASSWORD_NOT_MATCH,
                  })}
                  style={
                    errors.pasword_confirm
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
            </div>
            <button
              type="submit"
              onClick={handleSubmit(submit)}
              style={{
                backgroundColor: !loading ? "#3795fa" : "rgba(0, 0, 0, 0.5)",
                cursor: !loading ? "pointer" : "",
                color: "white",
                padding: "10px 20px",
                borderRadius: 8,
                width: "100%",
              }}
              disabled={loading}
            >
              Đăng ký
            </button>
            <div className="text-center already-account">
              Bạn có tài khoản?{" "}
              <a onClick={onNavigationLogin} className="click">
                Đăng nhập tại đây
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="bg_container" onClick={onClose}></div>
    </div>
  );
}
