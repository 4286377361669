import { Offer, dataProps } from "@/interface";
import create from "zustand";

export type offer = {
  offer: Offer | null;
  loading: boolean;
  offers: Offer[] | [];
  setField: (value: Offer[]) => void;
  setOffer: (value: Offer) => void;
  setLoading: (value: boolean) => void;
};

export const useOfferStore = create<offer>((set) => ({
  offers: [],
  offer: null,
  loading: false,
  setField: (data) => set((state) => ({ offers: data })),
  setOffer: (data) => set((state) => ({ offer: data })),
  setLoading: (data) => set((state) => ({ loading: data })),
}));
