import { useForm } from "react-hook-form";

import "./index.css";
import { useAuthStore } from "@/stores/auth";
import { IUserInfo } from "@/interface";
import { useNavigate } from "react-router-dom";
import { URL_BE, USER } from "@/constants/route";
import axios from "axios";
import { saveUserProfile } from "@/components/Function";
import { toast } from "react-toastify";
import { fieldLoginForm } from "@/types/props";
import { authType } from "@/types/enums";
import { useState } from "react";

export default function () {
  const { setType, setToken } = useAuthStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<fieldLoginForm>();

  const submit = async (value: fieldLoginForm) => {
    try {
      setLoading(true);
      const result = await axios.post(URL_BE + "/auth/login", value);
      const info: IUserInfo = result.data.data;
      const { token, id } = info;
      setToken(token);
      const { email, first_name, last_name, phone_number } = info.fields;
      saveUserProfile({
        access_token: token,
        id,
        email,
        first_name,
        last_name,
        phone_number,
        psw: "",
      });

      toast.success("Đăng nhập thành công");
      navigate(USER);
      setType(authType.NON_AUTH);
    } catch (error) {
      toast.error("Có lỗi xảy ra, xin vui lòng thử lại.");
    }
    setLoading(false);
  };

  const onNavigationRegister = () => {
    setType(authType.REGISTER);
  };

  const onClose = () => {
    setType(authType.NON_AUTH);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-body">
          <h4 className="modal-title fw-bold">
            <span>Thành viên đăng nhập</span>
          </h4>
          <small className="grey-300-clr">
            Xin chào! Chào mừng đến với tài khoản của bạn
          </small>
          <div
            className="alert alert-danger mt-3 login-fail"
            style={{ display: "none" }}
          ></div>
          <form className="mt-4" id="frmLogin" onSubmit={handleSubmit(submit)}>
            <div className="row item">
              <div className="item">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail"
                  {...register("email", { required: true })}
                  required={true}
                  style={
                    errors.email
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>

              <div className="item">
                <label htmlFor="password" className="form-label">
                  Mật khẩu
                </label>
                <input
                  type="password"
                  autoComplete="on"
                  className="form-control"
                  placeholder="Mật khẩu"
                  {...register("password", { required: true })}
                  style={
                    errors.password
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
              </div>
            </div>
            <input type="hidden" id="loginFrom" value="" />
            <button
              type="submit"
              onClick={handleSubmit(submit)}
              style={{
                backgroundColor: !loading ? "#3795fa" : "rgba(0, 0, 0, 0.5)",
                cursor: !loading ? "pointer" : "",
                color: "white",
                padding: "10px 20px",
                borderRadius: 8,
                width: "100%",
              }}
              disabled={loading}
            >
              Đăng nhập
            </button>
            <div className="text-center mt-4 already-account">
              Bạn chưa có tài khoản?{" "}
              <a onClick={onNavigationRegister} className="click">
                Đăng ký tại đây
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="bg_container" onClick={onClose}></div>
    </div>
  );
}
