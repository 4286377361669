import { useEffect, useState } from "react";
import "./index.css";
import { usePaymentStore } from "@/stores/payment";
import { TypeIParams } from "@/types/enums";

export default function () {
  const { params, customerInfo, setCustomerInfo } = usePaymentStore();
  const [customers, setCustomers] = useState<number[]>([]);
  let total = 1;
  useEffect(() => {
    if (params?.type === TypeIParams.HOTEL) {
      total = parseInt(params.adults) + parseInt(params.older);
    } else if (params?.type === TypeIParams.TOUR) {
      total = parseInt(params.number);
    }
    const arr: number[] = [];
    let customerInfoArr: any = [];
    for (let i = 0; i < total; i++) {
      arr.push(i);
      let strObject = "";
      strObject += `{"customer_number_${i + 1}":"","identity_card_${
        i + 1
      }":"","year_number_${i + 1}":""}`;

      customerInfoArr.push(JSON.parse(strObject));
    }
    setCustomers([...arr]);
    setCustomerInfo([...customerInfoArr]);
  }, [params]);

  const customerInfoInput = (pos: number, key: string, value: string) => {
    let obj: any = {};
    for (let i = 0; i < customerInfo.length; i++) {
      Object.keys(customerInfo[i]).forEach((item) => {
        let strObject = "";
        if (item === key) {
          strObject += `{"${key}":"${value}"}`;
          const object = JSON.parse(strObject);
          obj = { ...customerInfo[i], ...object };
        }
      });
    }
    customerInfo[pos] = obj;
    setCustomerInfo([...customerInfo]);
  };

  return (
    <div id="payment__body__customer-infomation">
      <div className="heading">
        <h2 style={{ marginTop: 20, marginBottom: 20 }}>
          Thông tin khách hàng
        </h2>
      </div>
      <div className="input_field">
        <div style={{ width: "100%", marginBottom: 40 }}>
          {params?.type !== TypeIParams.OFFER && (
            <span style={{ color: "red" }}>
              *Lưu ý: Nếu là trẻ em thì chỉ cần nhập "0" vào ô CMND.
            </span>
          )}
        </div>
        {customers.map((item, index) => {
          const customer_number = "customer_number_" + item + 1;
          const identity_card = "identity_card_" + item + 1;
          const year_number = "year_number_" + item + 1;
          return (
            <div className="input_container" key={index}>
              <div className="input_item">
                <label htmlFor={customer_number}>
                  Tên khách thứ {item + 1}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name={customer_number}
                  onChange={(e: any) => {
                    const key = "customer_number_" + (item + 1);
                    customerInfoInput(item, key, e.target.value);
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              <div className="input_item">
                <label htmlFor={identity_card}>
                  CMND khách thứ {item + 1}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name={identity_card}
                  onChange={(e: any) => {
                    const key = "identity_card_" + (item + 1);
                    customerInfoInput(item, key, e.target.value);
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              <div className="input_item">
                <label htmlFor={year_number}>
                  Năm sinh khách thứ {item + 1}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name={year_number}
                  min={1900}
                  max={new Date().getFullYear()}
                  onChange={(e: any) => {
                    if (parseInt(e.target.value) > 1900) {
                      const key = "year_number_" + (item + 1);
                      customerInfoInput(item, key, e.target.value);
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
