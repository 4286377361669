import { useEffect, useState } from "react";
import "./index.css";
import { useInvoiceStore } from "@/stores/invoice";
import { TypeIParams } from "@/types/enums";
import {
  getAttraction,
  getFastBuyTicket,
  getHotel,
  getInvoiceType,
  getOffer,
  getRoom,
  getTour,
} from "@/components/localStorage";

export default function () {
  const { invoice } = useInvoiceStore();
  const [total, setTotal] = useState<number>(0);
  const [product, setProduct] = useState<any>(null);
  const [room, setRoom] = useState<any>(null);
  const [totalPriceAdults, setTotalPriceAdults] = useState<number>(0);
  const [totalPriceKid, setTotalPriceKid] = useState<number>(0);
  const [totalPriceOlder, setTotalPriceOlder] = useState<number>(0);
  const [price, setPrice] = useState<string>("");

  const [name, setName] = useState<string>("");

  useEffect(() => {
    const type = getInvoiceType();

    if (invoice) {
      if (type === TypeIParams.HOTEL) {
        const strHotel = invoice.product_json;

        if (strHotel) {
          const hotel = JSON.parse(strHotel);
          const room = JSON.parse(hotel.fields.room_json);
          setProduct(hotel);
          setRoom(room);
          setTotal(room.price);
        }
      } else if (type === TypeIParams.FAST_BUY) {
        const fastBuy = invoice.product_json;

        if (fastBuy) {
          const item = JSON.parse(fastBuy);
          setTotalPriceAdults(item.Price ? item.Price : item.BasePrice);
          setTotalPriceKid(item.Price ? item.Price : item.BasePrice);
          setTotalPriceOlder(item.Price ? item.Price : item.BasePrice);
          setName(item.Name);
          setProduct(item);
          setTotal(item.Price ? item.Price : item.BasePrice);
        }
      } else if (type === TypeIParams.OFFER) {
        const offer = invoice.product_json;

        if (offer) {
          const item = JSON.parse(offer);
          setTotalPriceAdults(item.fields.price);
          setTotalPriceKid(item.fields.price);
          setTotalPriceOlder(item.fields.price);
          setName(item.fields.title);
          setTotal(item.fields.price);
        }
      } else if (type === TypeIParams.ATTRACTION) {
        const attraction = invoice.product_json;

        if (attraction) {
          const item = JSON.parse(attraction);
          setTotalPriceAdults(
            parseInt(invoice?.adults ?? "0") *
              parseInt(item.price.replaceAll(",", ""))
          );
          setTotalPriceKid(
            parseInt(invoice?.kid ?? "0") *
              parseInt(item.price.replaceAll(",", ""))
          );
          setTotalPriceOlder(
            parseInt(invoice?.older ?? "0") *
              parseInt(item.price.replaceAll(",", ""))
          );
          setName(item.title);
          setTotal(item.price);
        }
      } else if (type === TypeIParams.TOUR) {
        const tour = invoice.product_json;

        if (tour) {
          const item = JSON.parse(tour);
          setTotalPriceAdults(item.fields.price);
          setTotalPriceKid(item.fields.price);
          setTotalPriceOlder(item.fields.price);
          setName(item.fields.title);
          setTotal(item.fields.price);
        }
      }
    }
  }, []);

  const AttractionElements = () => {
    return (
      <div className="data_container">
        {parseInt(invoice?.adults ?? "0") === 0 ? (
          <></>
        ) : (
          <div className="row_container">
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>Người lớn</span>
            </div>
            <div className="row-item">
              <span>{total} vnd</span>
            </div>
            <div className="row-item">
              <span>{parseInt(invoice?.adults ?? "0")}</span>
            </div>
            <div className="row-item">
              <span>{totalPriceAdults.toLocaleString()} vnd</span>
            </div>
          </div>
        )}
        {parseInt(invoice?.kid ?? "0") === 0 ? (
          <></>
        ) : (
          <div className="row_container">
            <div className="row-item">
              <span>2</span>
            </div>
            <div className="row-item">
              <span>Trẻ em</span>
            </div>
            <div className="row-item">
              <span>{total} vnd</span>
            </div>
            <div className="row-item">
              <span>{parseInt(invoice?.kid ?? "0")}</span>
            </div>
            <div className="row-item">
              <span>{totalPriceKid.toLocaleString()} vnd</span>
            </div>
          </div>
        )}
        {parseInt(invoice?.older ?? "0") !== 0 ? (
          <div className="row_container">
            <div className="row-item">
              <span>3</span>
            </div>
            <div className="row-item">
              <span>Người già</span>
            </div>
            <div className="row-item">
              <span>{total} vnd</span>
            </div>
            <div className="row-item">
              <span>{parseInt(invoice?.older ?? "0")}</span>
            </div>
            <div className="row-item">
              <span>{totalPriceOlder.toLocaleString()} vnd</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const fastBuyElements = () => {
    return (
      <div className="data_container">
        {product && product.Name.indexOf("Người lớn") >= 0 ? (
          <></>
        ) : (
          <div className="row_container">
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>Người lớn</span>
            </div>
            <div className="row-item">
              <span>{totalPriceAdults.toLocaleString()} vnd</span>
            </div>
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>{total.toLocaleString()} vnd</span>
            </div>
          </div>
        )}
        {product && product?.Name.indexOf("Trẻ em") >= 0 ? (
          <></>
        ) : (
          <div className="row_container">
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>Trẻ em</span>
            </div>
            <div className="row-item">
              <span>{totalPriceKid.toLocaleString()} vnd</span>
            </div>
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>{totalPriceKid.toLocaleString()} vnd</span>
            </div>
          </div>
        )}
        {product && product?.Name.indexOf("Người cao tuổi") >= 0 ? (
          <div className="row_container">
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>Người già</span>
            </div>
            <div className="row-item">
              <span>{totalPriceOlder.toLocaleString()} vnd</span>
            </div>
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>{totalPriceOlder.toLocaleString()} vnd</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const OfferElements = () => {
    return (
      <div className="data_container">
        <div className="row_container">
          <div className="row-item">
            <span>1</span>
          </div>
          <div className="row-item">
            <span>{name}</span>
          </div>
          <div className="row-item">
            <span>{totalPriceAdults} vnd</span>
          </div>
          <div className="row-item">
            <span>1</span>
          </div>
          <div className="row-item">
            <span>{total.toLocaleString()} vnd</span>
          </div>
        </div>
      </div>
    );
  };

  const Room = () => {
    return (
      <>
        {room && (
          <div className="row_container">
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>{room.fields.title}</span>
            </div>
            <div className="row-item">
              <span>{room.fields.price} vnd</span>
            </div>
            <div className="row-item">
              <span>1</span>
            </div>
            <div className="row-item">
              <span>{room.fields.price} vnd</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const TourElements = () => {
    return (
      <div className="data_container">
        <div className="row_container">
          <div className="row-item">
            <span>1</span>
          </div>
          <div className="row-item">
            <span>{name}</span>
          </div>
          <div className="row-item">
            <span>{totalPriceAdults} vnd</span>
          </div>
          <div className="row-item">
            <span>1</span>
          </div>
          <div className="row-item">
            <span>{total.toLocaleString()} vnd</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="invoice_detail_container">
      <h2>Chi tiết đặt</h2>
      <div className="table-container">
        <div className="heading_container">
          <div className="col-item">
            <span>#</span>
          </div>
          <div className="col-item">
            <span>Mô tả</span>
          </div>
          <div className="col-item">
            <span>đơn giá</span>
          </div>
          <div className="col-item">
            <span>số lượng</span>
          </div>
          <div className="col-item">
            <span>thành tiền</span>
          </div>
        </div>

        {TypeIParams.OFFER !== getInvoiceType() ? (
          <div className="title_product">
            <span>{name}</span>
          </div>
        ) : (
          <></>
        )}

        {/* data */}
        <div className="data_container">
          {TypeIParams.HOTEL === getInvoiceType() && Room()}
          {TypeIParams.ATTRACTION === getInvoiceType() && AttractionElements()}
          {TypeIParams.FAST_BUY === getInvoiceType() && fastBuyElements()}
          {TypeIParams.OFFER === getInvoiceType() && OfferElements()}
          {TypeIParams.TOUR === getInvoiceType() && TourElements()}
        </div>
      </div>
    </div>
  );
}
