import { fieldAttraction, fieldParams } from "@/types/props";
import create from "zustand";

interface IContactInfo {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export type params = {
  params: fieldParams | null;
  customerInfo: any;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  info: fieldAttraction | null;
  isLoading: boolean;
  submit: any;
  setField: (value: fieldParams) => void;
  setCustomerInfo: (value: any) => void;
  setContactInfo: (value: IContactInfo) => void;
  setInfo: (value: fieldAttraction) => void;
  setLoading: (value: boolean) => void;
  setSubmit: (value: any) => void;
};

export const usePaymentStore = create<params>((set) => ({
  params: null,
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  isLoading: false,
  customerInfo: [],
  info: null,
  submit: null,
  setField: (data) => set((state) => ({ params: data })),
  setCustomerInfo: (data) => set((state) => ({ customerInfo: data })),
  setContactInfo: (data) =>
    set((state) => ({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
    })),
  setInfo: (data) =>
    set((state) => ({
      info: data,
    })),
  setLoading: (data) =>
    set((state) => ({
      isLoading: data,
    })),
  setSubmit: (data) =>
    set((state) => ({
      submit: data,
    })),
}));
