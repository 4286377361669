import React, { useEffect } from "react";
import "./index.css";
import { useInvoiceStore } from "@/stores/invoice";
import { TypeIParams } from "@/types/enums";

export default function () {
  const { invoice } = useInvoiceStore();
  const title = () => {
    if (invoice) {
      switch (invoice.type) {
        case TypeIParams.ATTRACTION:
          return "mua vé";
        case TypeIParams.FAST_BUY:
          return "đặt vé";
        case TypeIParams.HOTEL:
          return "đặt phòng";
        case TypeIParams.OFFER:
          return "đặt";
        case TypeIParams.TOUR:
          return "đặt tour";
      }
    } else {
      return "";
    }
  };
  return (
    <div id="confirmation-tour_container">
      <h2>xác nhận {title()}</h2>
      <div className="box-container">
        <div className="box-item">
          <div className="left-box">
            <span>Mã hóa đơn :</span>
          </div>
          <div className="right-box">
            <span>{invoice?.record_id}</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>
              Mã{" "}
              {invoice && invoice.type === "attraction" ? "mua vé" : "đặt tour"}
              :
            </span>
          </div>
          <div className="right-box">
            <span>{invoice?.record_id}</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Tên khách hàng:</span>
          </div>
          <div className="right-box">
            <span>
              {invoice?.first_name} {invoice?.last_name}
            </span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Email:</span>
          </div>
          <div className="right-box">
            <span>{invoice?.email}</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Số điện thoại:</span>
          </div>
          <div className="right-box">
            <span>{invoice?.phone_number}</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Ngày đặt</span>
          </div>
          <div className="right-box">
            <span>{invoice?.date_buy}</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Ngày khởi hành</span>
          </div>
          <div className="right-box">
            <span>{invoice?.date_check_in}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
