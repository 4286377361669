import { ContentLayout } from "@/components/Layout";
import { HotelDetail } from "../overview";
import { Policies } from "../policy";
import { Facilities } from "../Facilities";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BE } from "@/constants/route";
import { useParams } from "react-router-dom";
import Loading from "@/components/Loading";
import { useHotelStore } from "@/stores/hotel";
import { useLoadingStore } from "@/stores/global";

export default function () {
  const { title } = useParams();
  const { setField } = useHotelStore();
  const { setLoading } = useLoadingStore();

  const fetchData = async () => {
    setLoading(true);
    const result = await axios.get(
      URL_BE + "/hotels/hotel-by-category/hotels/hotel/" + title
    );
    setField(result.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContentLayout>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#dee2e6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 16,
        }}
      >
        <HotelDetail />
        <Policies />
        <Facilities />
      </div>
    </ContentLayout>
  );
}
