import React, { useEffect, useState } from "react";
import "./index.css";
import { useInvoiceStore } from "@/stores/invoice";
import {
  getAttraction,
  getFastBuyTicket,
  getHotel,
  getInvoiceType,
  getOffer,
  getRoom,
  getTour,
} from "@/components/localStorage";
import { TypeIParams } from "@/types/enums";

export default function () {
  const { setField, invoice } = useInvoiceStore();
  const [pricePrd, setPrice] = useState<string>("");

  useEffect(() => {
    const type = getInvoiceType();

    if (invoice) {
      if (type === TypeIParams.HOTEL) {
        const strHotel = invoice.product_json;
        if (strHotel) {
          const hotel = JSON.parse(strHotel);
          const room = JSON.parse(hotel.fields.room_json).fields;
          setPrice(room.price);
        }
      } else if (type === TypeIParams.FAST_BUY) {
        const fastBuy = invoice.product_json;
        if (fastBuy) {
          const item = JSON.parse(fastBuy);
          setPrice(item.Price ? item.Price : item.BasePrice);
        }
      } else if (type === TypeIParams.OFFER) {
        const offer = invoice.product_json;
        if (offer) {
          const item = JSON.parse(offer);
          setPrice(item.fields.price);
        }
      } else if (type === TypeIParams.ATTRACTION) {
        const attraction = invoice.product_json;
        if (attraction) {
          const item = JSON.parse(attraction);
          setPrice(item.price);
        }
      } else if (type === TypeIParams.TOUR) {
        const tour = invoice.product_json;
        if (tour) {
          const item = JSON.parse(tour);
          setPrice(item.fields.price);
        }
      }
    }
  }, []);

  return (
    <div id="total-tour_container">
      <h2>Thành tiền</h2>
      <div className="box-container">
        <div className="box-item slight">
          <div className="left-box">
            <span>Tổng cộng:</span>
          </div>
          <div className="right-box">
            <span>{pricePrd.toLocaleString()} vnd</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Thành tiền:</span>
          </div>
          <div className="right-box">
            <span>{pricePrd.toLocaleString()} vnd</span>
          </div>
        </div>
        <div className="box-item">
          <div className="left-box">
            <span>Trả trước</span>
          </div>
          <div className="right-box">
            <span>{pricePrd.toLocaleString()} vnd</span>
          </div>
        </div>
      </div>
    </div>
  );
}
